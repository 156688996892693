import { useState } from "react";
import Button from "../../commons/Button/Button";
import { Dropdown } from "../../commons/Dropdown/Dropdown";
import TextInput from "../../commons/TextInput/TextInput";
import Typography from "../../commons/Typography/Typography";
import "../../css/ScrollbarStyles.css";
import { useNavigate } from "react-router";

const transactions = [
    {
        id: 1,
        title: "transaction_87183_1709633735_4n2ZGvEHKC5X7Fc1",
        date: "2024/03/05 at 11:15 am"
    },
    {
        id: 2,
        title: "transaction_87183_1709633735_4n2ZGvEHKC5X7Fc1",
        date: "2024/03/05 at 11:15 am"
    },
    {
        id: 3,
        title: "transaction_87183_1709633735_4n2ZGvEHKC5X7Fc1",
        date: "2024/03/05 at 11:15 am"
    },
    {
        id: 4,
        title: "transaction_87183_1709633735_4n2ZGvEHKC5X7Fc1",
        date: "2024/03/05 at 11:15 am"
    },

];

const filterOptions = [
  { value: 'email', label: 'Email' },
  { value: 'name', label: 'Name' },
  { value: 'id', label: 'ID' },
]; 

const Transactions = () => {
    const navigate = useNavigate();
    const [filtersCount, setFiltersCount] = useState<number>(1);

    const addFilter = () => {
      setFiltersCount(prevCount => prevCount + 1);
    };
  return (
    <div className="flex px-30 pt-55 pb-20 flex-col bg-light-grey-1 h-full">
      <div className="flex justify-between flex-wrap content-center mb-30  ">
        <h2 className="text-dark-grey font-bold text-2xl">Transactions</h2>
        <Button type="secondary" border id="Transactions.AddTransactionButton">Add New Transaction</Button>
      </div>
      <div className=" mb-20 flex gap-20"><Button type="secondary" border onClick={addFilter} id="Transactions.AddFilterButton">Add filter</Button>
      {
   Array.from({ length: filtersCount }, (_, index) => index + 1).map((_, index) => {
    return (
      <div key={index} className="flex gap-7">
        <Dropdown options={filterOptions} className="max-w-123 w-full" />
        <TextInput customInputStyles="h-12 w-full" placeholder="Containing" />
      </div>
    );
  })
}
      <Button type="main" id="Transactions.ApplyButton">Apply</Button>
      </div>
      <div className="overflow-x-auto rounded-xl border-light-grey-3 border-solid border">
      <table className="table-auto w-full overflow-x-auto bg-white rounded-xl border-collapse text-dark-grey">
        <thead className="sticky top-0">
          <tr className=" bg-gray-100 h-48 bg-light-grey-4 ">
            <th className="pl-20 text-left"><Typography weight="extrabold">Title</Typography></th>
            <th className="pl-20 text-left"><Typography weight="extrabold">Date</Typography></th>
          </tr>
        </thead>
        <tbody>         
          {transactions.map(transaction => (
            <tr  className="cursor-pointer border-light-grey-3 border-solid border-t hover:bg-light-grey-1" key={transaction.id} onClick={()=>navigate(`/transactions/${transaction.id}`)}>
                <td className="px-20 py-20"><Typography weight="semibold">{transaction.title}</Typography></td>
                <td className="px-20 py-20"><Typography weight="semibold">{transaction.date}</Typography></td>
            </tr>
        ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};
export default Transactions;
