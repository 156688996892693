import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import axiosInstance from '../../../utils/axios';
import { defaultQueryOptions, QueryKeys } from '../../querys/querys.enum';
import { B2BSubscriptionsResponse } from '../../../models/Interface/B2BSubscription.interface';

export interface IFileterInterface {
  value: string;
  label: string;
  search?: string;
}
export interface IFilters {
  organizationName?: string;
  search?: string;
  adminEmail?: boolean;
  subscriptionId?: string;
  orgId?: string;
}

const useB2BSubscriptions = (isEnabled: boolean, params: IFilters) => {
  return useInfiniteQuery<B2BSubscriptionsResponse>({
    queryKey: [QueryKeys.B2B_SUBSCRIPTIONS, params],
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1, queryKey }) => {
      const d = queryKey[1] as IFilters;

      const adminEmail = d.adminEmail
        ? `&adminEmail=${encodeURIComponent(d.adminEmail)}`
        : '';
      const organizationName = d.organizationName
        ? `&organizationName=${encodeURIComponent(d.organizationName)}`
        : '';
      const subscriptionId = d.subscriptionId
        ? `&subscriptionId=${encodeURIComponent(d.subscriptionId)}`
        : '';
      const search = d.search ? `&search=${encodeURIComponent(d.search)}` : '';
      const orgId = d.orgId ? `&orgId=${encodeURIComponent(d.orgId)}` : '';

      return (
        await axiosInstance.get(
          `/b2b-subscriptions?page=${pageParam}${adminEmail}${organizationName}${subscriptionId}${search}${orgId}`
        )
      ).data;
    },
    getNextPageParam: (lastPage, allPages, lastPageParam, allParams) => {
      if (lastPage.meta.totalPage === lastPage.meta.page) return undefined;
      return lastPage.meta.page + 1;
    },
    ...defaultQueryOptions,
    refetchOnWindowFocus: true,
    initialData: undefined,
    enabled: isEnabled,
    placeholderData: keepPreviousData,
  });
};
export default useB2BSubscriptions;
