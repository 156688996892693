import axiosInstance from '../../../utils/axios';
import { IErrorResponse, QueryKeys } from '../../querys/querys.enum';
import { AxiosError } from 'axios';
import { User } from '../../../models/Interface/User.interface';
import { useAppQuery } from '../../querys/useAppQuery';


const useUser = (userId: string | undefined) => {
  const { data, isLoading, isError, error, refetch,isFetching,isPending } =   useAppQuery<User, AxiosError<IErrorResponse>>({
    queryKey: [
      QueryKeys.USER,
      {
        userId,
      },
    ],
    queryFn: async () => {
      return (await axiosInstance.get(`/users/main/${userId}`)).data;
    },
    enabled: !!userId,
    retry: 0,
  });


  return { data, isLoading, isError, error, refetch,isFetching,isPending };
};

export default useUser;
