import { useLocation } from 'react-router-dom';
import { Formik, FormikValues } from 'formik';
import Button from '../../commons/Button/Button';
import { FormField } from '../../commons/FormField/FormField';
import '../../css/ScrollbarStyles.css';
import TextInput from '../../commons/TextInput/TextInput';
import Typography from '../../commons/Typography/Typography';
import useMe from '../../state/hooks/auth/useMe';
import { InputErrorMessage } from '../../commons/error';
import getChangedValues from '../../utils/helpers/getChangedValues';
import FormatDate from '../../utils/helpers/formatDate';
import useB2BAdmin from '../../state/hooks/b2b-admins/useB2BAdmin';
import generateRandomPassword from '../../utils/helpers/generatePassword';
import useUpdateB2BAdmin from '../../state/hooks/b2b-admins/useUpdateB2BAdmin';
import useUpdateB2BAdminPassword from '../../state/hooks/b2b-admins/useUpdateB2BAdminPassword';
import { useState } from 'react';

const B2BAdmin = () => {
  const query = useLocation();
  const b2bAdminId = query.pathname.replace('/b2b-admins/', '');
  const { data: meData, isLoading: mLoading } = useMe();

  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const [isAdminUpdated, setIsAdminUpdated] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [adminError, setAdminError] = useState('');

  const { mutate: mutatePasswordUpdate, isPending: UDeleteSubUser } =
    useUpdateB2BAdminPassword({
      onSuccess: (data, variables, context) => {
        setIsPasswordUpdated(true);
        checkAllUpdates();
        refetch();
      },
      onError: (error, variables, context) => {
        setPasswordError(
          error?.response?.data?.message || 'Password update failed.'
        );
        checkAllErrors();
      },
    });

  const { mutate, isPending: UPending } = useUpdateB2BAdmin({
    onSuccess: (data, variables, context) => {
      setIsAdminUpdated(true);
      checkAllUpdates();
      refetch();
    },
    onError: (error, variables, context) => {
      setPasswordError(
        error?.response?.data?.message || 'Password update failed.'
      );
      checkAllErrors();
    },
  });

  const checkAllUpdates = () => {
    if (isPasswordUpdated && isAdminUpdated) {
      alert('B2B admin and password updated successfully');
      setIsPasswordUpdated(false);
      setIsAdminUpdated(false);
    }
  };

  const checkAllErrors = () => {
    if (passwordError || adminError) {
      const combinedErrorMessage = `${
        passwordError ? `Password Error: ${passwordError} ` : ''
      }${adminError ? `Admin Error: ${adminError}` : ''}`;
      alert(combinedErrorMessage);
      setPasswordError('');
      setAdminError('');
    }
  };

  const {
    refetch,
    data: BUData,
    isLoading,
    isPending,
    isError,
    error,
    isSuccess,
    isFetching,
  } = useB2BAdmin(b2bAdminId);

  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  if (isLoading || isPending || mLoading || isFetching)
    return <div>Loading...</div>;

  if (isError && error)
    return (
      <div className="flex flex-1 justify-center items-center h-full">
        <Typography>{JSON.stringify(error.response?.data.message)}</Typography>
      </div>
    );

  const initialValues = {
    id: BUData.b2bAdmin?.id || '',
    createdAt: BUData?.b2bAdmin?.createdAt || '',
    active: BUData?.b2bAdmin?.active || false,
    organizationName:
      BUData?.b2bAdmin?.organizations?.[0]?.organizationName || '',
    firstName: BUData?.b2bAdmin?.firstName,
    lastName: BUData?.b2bAdmin?.lastName,
    email: BUData?.b2bAdmin?.email,
    newPassword: '',
  };

  const handleSubmit = async (values: any) => {
    const { newPassword, ...changedValues } = getChangedValues(
      initialValues,
      values
    );

    if (newPassword) {
      mutatePasswordUpdate({ b2bAdminId, data: { newPassword } });
    }

    if (Object.keys(changedValues).length > 0) {
      mutate({ b2bAdminId, data: changedValues });
    }
  };

  const handleGeneratePassword = (
    setFieldValue: (arg0: string, arg1: string) => void
  ) => {
    const password = generateRandomPassword();
    setNewPassword(password);
    setFieldValue('newPassword', password);
    setShowPassword(true);
    setPasswordFieldType('text'); // Show the generated password as text initially
  };

  return (
    <div className="flex overflow-x-auto px-30 pt-80 pb-20 flex-col bg-light-grey-1 h-full">
      <h2 className="text-dark-grey font-bold text-2xl">B2B Admin</h2>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik: FormikValues) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-50 mb-30">
              <FormField label="ID">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="B2BAdmin.id"
                  defaultValue={formik.values.id}
                  name="id"
                  disabled
                />
              </FormField>
              <FormField label="First Name">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('firstName', e?.target.value)
                  }
                  id="B2BAdmin.FirstNameField"
                  value={formik.values.firstName}
                  name="firstName"
                />
              </FormField>
              <FormField label="Last Name">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('lastName', e?.target.value)
                  }
                  id="B2BAdmin.lastNameField"
                  value={formik.values.lastName}
                  name="lastName"
                />
              </FormField>
              <FormField label="Admin Email">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('email', e?.target.value)
                  }
                  id="B2BAdmin.EmailField"
                  value={formik.values.email}
                  name="email"
                />
                {InputErrorMessage({ props: formik, type: 'email' })}
              </FormField>
              <FormField label="Organization Name">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('organizationName', e?.target.value)
                  }
                  id="B2BAdmin.organizationNameField"
                  value={formik.values.organizationName}
                  name="organizationName"
                  disabled
                />
              </FormField>
              <FormField label="Creation Date">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('createdAt', e?.target.value)
                  }
                  id="B2BAdmin.CreationDate"
                  value={FormatDate(formik.values.createdAt) || ''}
                  name="createdAt"
                  disabled
                />
              </FormField>
              <FormField label="Active">
                <input
                  type="checkbox"
                  checked={formik.values.active}
                  onChange={formik.handleChange}
                  name="active"
                  id="B2BAdmin.ActiveCheckbox"
                />
              </FormField>
              <FormField label="Set New Password">
                <Button
                  type="secondary"
                  buttonType="button"
                  onClick={() => handleGeneratePassword(formik.setFieldValue)}
                >
                  Set New Password
                </Button>
                {showPassword && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '10px',
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                      }}
                    >
                      <TextInput
                        customStyles="flex-col w-360"
                        placeholder=""
                        customInputStyles="h-12 w-full"
                        type={passwordFieldType}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                          formik.setFieldValue('newPassword', e.target.value);
                        }}
                        value={formik.values.newPassword || newPassword}
                        name="newPassword"
                      />
                      <div style={{ position: 'absolute', bottom: '-20px' }}>
                        {InputErrorMessage({
                          props: formik,
                          type: 'newPassword',
                        })}
                      </div>
                    </div>
                    <div style={{ display: 'flex', marginLeft: '10px' }}>
                      <Button
                        type="secondary"
                        buttonType="button"
                        onClick={() =>
                          setPasswordFieldType(
                            passwordFieldType === 'text' ? 'password' : 'text'
                          )
                        }
                        style={{ marginLeft: '10px' }}
                      >
                        {passwordFieldType === 'text' ? 'Hide' : 'Show'}
                      </Button>
                      <Button
                        type="secondary"
                        buttonType="button"
                        onClick={() => {
                          setNewPassword('');
                          setShowPassword(false);
                          formik.setFieldValue('newPassword', '');
                        }}
                        style={{ marginLeft: '10px' }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
              </FormField>
            </div>
            <div>
              <Button
                type="main"
                id="B2BAdmin.UpdateButton"
                isLoading={UPending}
                buttonType="submit"
              >
                Update
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default B2BAdmin;
