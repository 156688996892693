import axiosInstance from '../../../utils/axios';
import { IErrorResponse, QueryKeys } from '../../querys/querys.enum';
import { AxiosError } from 'axios';
import { useAppQuery } from '../../querys/useAppQuery';
import { PromptsResponse } from '../../../models/Interface/Prompt.interface';

const usePrompts = () => {
  return useAppQuery<PromptsResponse, AxiosError<IErrorResponse>>({
    queryKey: [QueryKeys.PROMPTS],
    queryFn: async () => {
      return (await axiosInstance.get(`/prompts`)).data;
    },
    retry: 3,
  });
};
export default usePrompts;
