import { Fragment, useEffect, useState } from 'react';
import Button from '../../commons/Button/Button';
import Typography from '../../commons/Typography/Typography';
import '../../css/ScrollbarStyles.css';
import { useNavigate } from 'react-router';
import useSubscriptions, {
  IFilters,
} from '../../state/hooks/subscriptions/useSubscriptions';
import { FiltersComponent } from '../../commons/FiltersComponent/FiltersComponent';
import formatDate from "../../utils/helpers/formatDate";
import nextBillingDate from "../../utils/helpers/nextBillingDate";

const filterOptions = [
  { value: 'subscriptionId', label: 'Subscription ID' },
  { value: 'email', label: 'email' },
];

const Subscriptions = () => {
  const navigate = useNavigate();
  const [filtersCount, setFiltersCount] = useState<IFilters>({});

  const {
    data,
    isLoading,
    isError,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useSubscriptions(true, filtersCount);

  useEffect(() => {
    addFilter();
  }, []);

  const addFilter = () => {
    const newFilter = filterOptions.find(
      (option) => !Object.keys(filtersCount).includes(option.value)
    );
    if (newFilter) {
      setFiltersCount((prevFilters) => ({
        ...prevFilters,
        [newFilter.value]: '',
      }));
    }
  };

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <=
      e.target.clientHeight + 100;

    if (bottom) {
      if (isFetchingNextPage && hasNextPage) return;
      fetchNextPage();
    }
  };

  return (
    <div className="flex px-30 pt-55 pb-20 flex-col bg-light-grey-1 h-full max-w-1200">
      <h2 className="text-dark-grey font-bold text-2xl mb-30">
        Subscriptions
      </h2>
      <div className="flex justify-between items-center mb-30  ">
        <FiltersComponent
          setFiltersCount={setFiltersCount}
          filterOptions={filterOptions} // Pass filterOptions as prop
          filtersCount={filtersCount}
        />
        <Button
          type="secondary"
          border
          onClick={() => navigate('/new-subscription')}
        >
          Add New Subscription
        </Button>
      </div>
      <div
        className="overflow-x-auto rounded-xl border-light-grey-3 border-solid border"
        style={{ maxWidth: '120rem' }}
        onScroll={handleScroll}
      >
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>{JSON.stringify(error)}</div>
        ) : (
          <table
            className="table-auto w-full bg-white rounded-xl border-collapse text-dark-grey"
            style={{ minWidth: '2032px' }}
          >
            <thead className="sticky top-0">
              <tr className=" bg-gray-100 h-48 bg-light-grey-4 ">
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Email</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">
                    Subscription ID
                  </Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">
                    Payment Gateway
                  </Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Package</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Status</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">
                    Sub user ID
                  </Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">
                   Creation Date
                  </Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">
                   Next Billing Date
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.pages.map((subscriptionList) => (
                <Fragment key={subscriptionList.meta.page}>
                  {subscriptionList.data?.map((sub, index) => (
                    <tr
                      className="cursor-pointer border-light-grey-3 border-solid border-t hover:bg-light-grey-1"
                      key={index}
                      onClick={() =>
                        {
                          const url = `/subscriptions/${sub.id}`;
                          window.open(url, '_blank');
                        }
                      }
                    >
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {sub.subUser?.user?.email}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {sub.id}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {sub.paymentGateway}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {sub.package}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {sub.status}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {sub.subUser?.id}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {formatDate(sub?.createdAt) || ''}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {nextBillingDate(sub?.nextBillingDate) || ''}
                        </Typography>
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
export default Subscriptions;
