import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance, { CustomAxiosError } from '../../../utils/axios';
import { IErrorResponse, QueryKeys } from '../../querys/querys.enum';
import { MutationKeys } from '../../mutation/mutation.enum';
import {
  IOrganizationUpdateResponse,
  Organization,
  UpdateOrganizationVariables,
} from '../../../models/Interface/Organization.interface';

const useUpdateOrganizationReq = async (
  orgId: string,
  data: Partial<Organization>
) => {
  const response = await axiosInstance.put<IOrganizationUpdateResponse>(
    `organizations/${orgId}`,
    data
  );

  return response.data;
};

const useUpdateOrganization = (
  options?: Partial<
    UseMutationOptions<
      IOrganizationUpdateResponse,
      AxiosError<IErrorResponse>,
      UpdateOrganizationVariables
    >
  >
) => {
  return useMutation<
    IOrganizationUpdateResponse,
    AxiosError<IErrorResponse>,
    UpdateOrganizationVariables
  >({
    mutationKey: [MutationKeys.UPDATE_ORGANIZATION],
    mutationFn: async ({ orgId, data }) => {
      return await useUpdateOrganizationReq(orgId, { ...data });
    },
    ...options,
    onSuccess: (data, variables, cx) => {
      options?.onSuccess?.(data, variables, cx);

      alert('Organization updated successfully...');
    },
    onError: (error: CustomAxiosError, variables, context) => {
      alert(error?.response?.data?.message);
      options?.onError?.(error, variables, context);
    },
  });
};
export default useUpdateOrganization;
