enum MutationKeys {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  UPDATE_SUB_USER = 'UPDATE_SUB_USER',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_PROMPT = 'UPDATE_PROMPT',
  CREATE_PROMPT = 'CREATE_PROMPT',
  UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION',
  DELETE_SUB_USER = 'DELETE_SUB_USER',
  DELETE_MAIN_USER = 'DELETE_MAIN_USER',
  UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION',
  UPDATE_B2B_ADMIN = 'UPDATE_B2B_ADMIN',
  UPDATE_B2B_SUBSCRIPTION = 'UPDATE_B2B_SUBSCRIPTION',
  UPDATE_B2B_ADMIN_PASS = 'UPDATE_B2B_ADMIN_PASS',
}
export { MutationKeys };
