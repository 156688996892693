import { useLocation } from 'react-router-dom';
import { Formik, FormikValues } from 'formik';
import Button from '../../commons/Button/Button';
import { FormField } from '../../commons/FormField/FormField';
import '../../css/ScrollbarStyles.css';
import TextInput from '../../commons/TextInput/TextInput';
import Typography from '../../commons/Typography/Typography';
import useMe from '../../state/hooks/auth/useMe';
import { DropdownWithSearch } from '../../commons/Dropdown';
import getChangedValues from '../../utils/helpers/getChangedValues';
import nextBillingDate from '../../utils/helpers/nextBillingDate';
import formatDate from '../../utils/helpers/formatDate';
import useB2BSubscription from '../../state/hooks/b2b-subscriptions/useB2BSubscription';
import useUpdateB2BSubscription from '../../state/hooks/b2b-subscriptions/useUpdateB2BSubscription';
import { toUnixTimestamp } from '../../utils/helpers/unixTimestamp';

const B2BSubscription = () => {
  const query = useLocation();
  const subscriptionId = query.pathname.replace('/b2b-subscriptions/', '');
  const { data: meData, isLoading: mLoading } = useMe();
  const { mutate, isPending: UPending } = useUpdateB2BSubscription({
    onSuccess: (data, variables, context) => {
      console.warn('B2B subscription update success');
    },
  });

  const statusOptions = [
    { label: 'draft', value: 'draft' },
    { label: 'active', value: 'active' },
    { label: 'canceled', value: 'canceled' },
  ];

  const packageOptions = [
    { label: 'Free Subscription', value: 'Free Subscription' },
    { label: 'Monthly Subscription', value: 'Monthly Subscription' },
    { label: 'Quarterly Subscription', value: 'Quarterly Subscription' },
    { label: 'Yearly Subscription', value: 'Yearly Subscription' },
  ];

  const {
    data: SData,
    isLoading,
    isPending,
    isError,
    error,
    isFetching,
  } = useB2BSubscription(subscriptionId);

  if (isLoading || isPending || mLoading || isFetching)
    return <div>Loading...</div>;

  if (isError && error)
    return (
      <div className="flex flex-1 justify-center items-center h-full">
        <Typography>{JSON.stringify(error.response?.data.message)}</Typography>
      </div>
    );

  const initialValues = {
    id: SData.data?.id || '',
    createdAt: SData?.data?.createdAt || '',
    status: SData?.data?.status || '',
    amount: SData?.data?.amount || 0,
    nextBillingDate: SData?.data?.nextBillingDate || '',
    country: SData?.data?.country || '',
    subscriptionId: SData?.data?.subscriptionId || '',
    licenseCount: SData?.data?.licenseCount || 0,
    orgId: SData?.data?.organization?.id || '',
    toBeCancelled: SData?.data?.toBeCancelled || false,
    planId: SData?.data?.planId || '',
    taxRate: SData?.data?.taxRate || 0,
    currency: SData?.data?.currency || '',
    package: SData?.data?.package || '',
    provider: SData?.data?.provider || '',
  };

  const handleSubmit = async (values: any) => {
    const changedValues = getChangedValues(initialValues, values);

    if (changedValues.nextBillingDate) {
      changedValues.nextBillingDate = toUnixTimestamp(
        changedValues.nextBillingDate
      );
    }

    if (Object.keys(changedValues).length > 0) {
      mutate({ subscriptionId, data: changedValues });
    }
  };

  return (
    <div className="flex overflow-x-auto px-30 pt-80 pb-20 flex-col bg-light-grey-1 h-full">
      <h2 className="text-dark-grey font-bold text-2xl">B2B Subscription</h2>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik: FormikValues) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-50 mb-30">
              <FormField label="ID">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="B2BSubscription.id"
                  defaultValue={formik.values.id}
                  name="id"
                  disabled
                />
              </FormField>
              <FormField label="Creation Date">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="B2BSubscription.createdAt"
                  defaultValue={formatDate(formik.values.createdAt) || ''}
                  name="createdAt"
                  disabled
                />
              </FormField>
              <FormField label="Provider">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('provider', e.target.value)
                  }
                  id="B2BSubscription.provider"
                  defaultValue={formik.values.provider}
                  name="provider"
                />
              </FormField>
              <FormField label="Package">
                <DropdownWithSearch
                  options={packageOptions ?? []}
                  className="w-360"
                  onChange={(e) => formik.setFieldValue('package', e?.value)}
                  id="B2BSubscription.PackageSelect"
                  value={formik.values.package}
                />
              </FormField>
              <FormField label="Status">
                <DropdownWithSearch
                  options={statusOptions ?? []}
                  className="w-360"
                  onChange={(e) => formik.setFieldValue('status', e?.value)}
                  id="B2BSubscription.Status"
                  value={formik.values.status}
                />
              </FormField>
              <FormField label="Amount">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="number"
                  onChange={(e) =>
                    formik.setFieldValue('amount', +e.target.value)
                  }
                  id="B2BSubscription.amount"
                  defaultValue={formik.values.amount}
                  name="amount"
                />
              </FormField>
              <FormField label="City">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder="City"
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) => formik.setFieldValue('city', e.target.value)}
                  id="B2BSubscription.city"
                  defaultValue={formik.values.city}
                  name="city"
                />
              </FormField>
              <FormField label="Country">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('country', e.target.value)
                  }
                  id="B2BSubscription.country"
                  defaultValue={formik.values.country}
                  name="country"
                />
              </FormField>
              <FormField label="Next Billing Date">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('nextBillingDate', e.target.value)
                  }
                  id="B2BSubscription.nextBillingDate"
                  defaultValue={nextBillingDate(formik.values.nextBillingDate)}
                  name="nextBillingDate"
                />
              </FormField>
              <FormField label="Subscription ID">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('subscriptionId', e.target.value)
                  }
                  id="B2BSubscription.subscriptionId"
                  defaultValue={formik.values.subscriptionId}
                  name="subscriptionId"
                />
              </FormField>
              <FormField label="State">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder="State"
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('state', e.target.value)
                  }
                  id="B2BSubscription.state"
                  defaultValue={formik.values.state}
                  name="state"
                />
              </FormField>
              <FormField label="License Count">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder="Total Licenses"
                  customInputStyles="h-12 w-full"
                  type="number"
                  min="0"
                  onChange={(e) =>
                    formik.setFieldValue('licenseCount', +e.target.value)
                  }
                  id="B2BSubscription.licenseCount"
                  defaultValue={formik.values.licenseCount}
                  name="licenseCount"
                />
              </FormField>
              <FormField label="Tax Rate">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="number"
                  onChange={(e) =>
                    formik.setFieldValue('taxRate', +e.target.value)
                  }
                  id="B2BSubscription.taxRate"
                  step="0.1"
                  min="0"
                  defaultValue={formik.values.taxRate}
                  name="taxRate"
                />
              </FormField>
              <FormField label="To Be Cancelled">
                <input
                  type="checkbox"
                  checked={formik.values.toBeCancelled}
                  onChange={formik.handleChange}
                  name="toBeCancelled"
                  id="B2BSubscription.ToBeCancelled"
                />
              </FormField>
              <FormField label="Plan ID">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('planId', e.target.value)
                  }
                  id="B2BSubscription.planId"
                  defaultValue={formik.values.planId}
                  name="planId"
                />
              </FormField>
            </div>
            <div>
              <Button
                type="main"
                id="Subscription.UpdateButton"
                onClick={() => handleSubmit(formik.values)}
                isLoading={UPending}
              >
                Update
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default B2BSubscription;
