import axiosInstance from '../../../utils/axios';
import { IErrorResponse, QueryKeys } from '../../querys/querys.enum';
import { AxiosError } from 'axios';
import { useAppQuery } from '../../querys/useAppQuery';
import { Prompt } from '../../../models/Interface/Prompt.interface';

const usePrompt = (promptType: string) => {
  return useAppQuery<Prompt, AxiosError<IErrorResponse>>({
    queryKey: [
      QueryKeys.PROMPT,
      {
        promptType,
      },
    ],

    queryFn: async () => {
      return (await axiosInstance.get(`/prompts/${promptType}`)).data;
    },
    enabled: !!promptType,
    retry: 0,
  });
};
export default usePrompt;
