const getChangedValues = (initial: any, current: any) => {
  const changes: any = {};
  Object.keys(initial).forEach((key) => {
    if (initial[key] !== current[key]) {
      changes[key] = current[key];
    }
  });
  return changes;
};

export default getChangedValues;
