import { QueryClient } from "@tanstack/react-query";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister"
import { persistQueryClient } from "@tanstack/query-persist-client-core"
import { PersistQueryClientOptions } from "@tanstack/react-query-persist-client";
import { QueryKeys } from "./querys/querys.enum";
const queryClient = new QueryClient({
    defaultOptions: {

        queries: {
            refetchOnWindowFocus: false,
            retry: 3,
            gcTime: 1000 * 60 * 60 * 1, // 1 hours
            initialData: [],


        },
    },
});
const localStoragePersister = createSyncStoragePersister({
    storage: window.localStorage,
    key: "queryClient",
})

persistQueryClient({
    queryClient: queryClient as any,
    persister: localStoragePersister,
    dehydrateOptions: {
        shouldDehydrateQuery: ({ queryKey, state }) => {
            if (queryKey[0] === QueryKeys.USER) {
                return true
            } else {
                return false
            }
        },
    }
})



export { queryClient };



