import React, { ReactNode } from 'react';
import Spinner from '../Spinner/Spinner';

type ButtonProps = {
  width?: string;
  maxWidth?: string;
  height?: string;
  margin?: string;
  padding?: string;
  paddingHorizontal?: string;
  paddingVertical?: string;
  fontSize?: string;
  fontWeight?: string;
  border?: boolean;
  borderWidth?: string;
  borderColor?: string;
  alignSelf?: string;
  type: 'main' | 'secondary' | undefined;
  buttonType?: 'button' | 'submit' | 'reset';
  ref?: any,
  disabled?: boolean;
  onClick?: () => void;
  children: ReactNode;
  isLoading?: boolean;
  // className?: string;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>;

const Button: React.FC<ButtonProps> = ({
  children,
  width,
  maxWidth,
  height,
  margin,
  padding,
  paddingHorizontal,
  paddingVertical,
  fontSize,
  fontWeight,
  border,
  borderWidth,
  borderColor,
  alignSelf,
  type,
  buttonType = 'button',
  ref,
  disabled,
  onClick,
  isLoading,
  // className,
  ...rest
}) => {
  let classNames = '';
  if (type === 'main') {
    classNames = `
      flex
      justify-center
      w-${width}
      max-w-${maxWidth}
      h-${height ? height : '30'}
      m-${margin ? margin : '0'}
      p-${padding ? padding : '0'}
      px-${paddingHorizontal ? paddingHorizontal : '30'}
      text-center
      bg-primary
      text-white
      text-${fontSize ? fontSize : 'sm'}
      rounded-md
      font-${fontWeight ? fontWeight : 'normal'}
      ${
        border &&
        (borderColor ? 'border-' + borderColor : 'border-light-grey-3')
      }
      ${border && (borderWidth ? 'border-' + borderWidth : 'border')}
      border-solid
      self-${alignSelf ? alignSelf : ''}
      hover:bg-hover
      hover:text-white
      transition
      items-center
    `;
  } else if (type === 'secondary') {
    classNames = `
      flex
      justify-center
      w-${width}
      max-w-${maxWidth}
      h-${height ? height : '30'}
      m-${margin ? margin : '0'}
      p-${padding ? padding : '0'}
      px-${paddingHorizontal ? paddingHorizontal : '30'}
      text-center
      bg-white
      text-black
      text-${fontSize ? fontSize : 'sm'}
      rounded-md
      font-${fontWeight ? fontWeight : 'normal'}
      ${
        border &&
        (borderColor ? 'border-' + borderColor : 'border-light-grey-3')
      }
      ${border && (borderWidth ? 'border-' + borderWidth : 'border')}
      border-solid
      self-${alignSelf ? alignSelf : ''}
      hover:bg-hover
      hover:text-white
      transition
      items-center
    `;
  }

  return (
    <button
      className={`${classNames} ${isLoading ? ' opacity-50' : ''}`}
      onClick={onClick}
      disabled={disabled}
      type={buttonType}
      ref={ref}
      {...rest}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  );
};

export default Button;
