import React from 'react';
import { InputErrorMessage } from '../error';
import { FormikValues } from 'formik';

type Props = {
  label?: string;
  placeholder: string;
  customStyles?: string;
  customInputStyles?: string;
  labelStyles?: string;
  formik?: FormikValues;
  name?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TextInput: React.FC<Props> = ({
  label,
  placeholder,
  customStyles,
  customInputStyles,
  labelStyles,
  formik,
  name,
  ...rest
}) => {
  return (
    <div className={`flex ${customStyles} items-baseline`}>
      {label && (
        <label
          className={`${
            customStyles?.includes('flex-col') ? 'mb-2' : 'mr-2'
          } text-dark-grey font-bold text-sm ${labelStyles}`}
        >
          {label}
        </label>
      )}

      <input
        type="text"
        className={`bg-light-grey-2 rounded border-light-grey-3 border-solid border text-dark-grey text-sm font-semibold px-15 p-5 focus:outline-none ${customInputStyles}`}
        placeholder={placeholder}
        {...rest}
      />
      {InputErrorMessage({props: formik!, type: name!})}
    </div>
  );
};

export default TextInput;
