import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance, { CustomAxiosError } from '../../../utils/axios';
import { IErrorResponse } from '../../querys/querys.enum';
import { MutationKeys } from '../../mutation/mutation.enum';

import {
  B2BAdminUpdatePasswordResponse,
  newPassword,
  UpdateB2BAdminPasswordVariables,
} from '../../../models/Interface/B2BAdmin.interface';

const useUpdateB2BAdminPasswordReq = async (
  b2bAdminId: string,
  data: newPassword
) => {
  const response = await axiosInstance.put<B2BAdminUpdatePasswordResponse>(
    `b2b-admins/update-password/${b2bAdminId}/cms`,
    data
  );

  return response.data;
};

const useUpdateB2BAdminPassword = (
  options?: Partial<
    UseMutationOptions<
      B2BAdminUpdatePasswordResponse,
      AxiosError<IErrorResponse>,
      UpdateB2BAdminPasswordVariables
    >
  >
) => {
  return useMutation<
    B2BAdminUpdatePasswordResponse,
    AxiosError<IErrorResponse>,
    UpdateB2BAdminPasswordVariables
  >({
    mutationKey: [MutationKeys.UPDATE_B2B_ADMIN_PASS],
    mutationFn: async ({ b2bAdminId, data }) => {
      return await useUpdateB2BAdminPasswordReq(b2bAdminId, { ...data });
    },
    ...options,
    onSuccess: (data, variables, cx) => {
      options?.onSuccess?.(data, variables, cx);
    },
    onError: (error: CustomAxiosError, variables, context) => {
      alert(error?.response?.data?.message);
      options?.onError?.(error, variables, context);
    },
  });
};
export default useUpdateB2BAdminPassword;
