import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance, { CustomAxiosError } from '../../../utils/axios';
import { IErrorResponse } from '../../querys/querys.enum';
import { MutationKeys } from '../../mutation/mutation.enum';
import {
  B2BAdmin,
  B2BAdminUpdateResponse,
  UpdateB2BAdminVariables,
} from '../../../models/Interface/B2BAdmin.interface';

const useUpdateB2BAdminReq = async (
  b2bAdminId: string,
  data: Partial<B2BAdmin>
) => {
  const response = await axiosInstance.put<B2BAdminUpdateResponse>(
    `b2b-admins/update/${b2bAdminId}/cms`,
    data
  );

  return response.data;
};

const useUpdateB2BAdmin = (
  options?: Partial<
    UseMutationOptions<
      B2BAdminUpdateResponse,
      AxiosError<IErrorResponse>,
      UpdateB2BAdminVariables
    >
  >
) => {
  return useMutation<
    B2BAdminUpdateResponse,
    AxiosError<IErrorResponse>,
    UpdateB2BAdminVariables
  >({
    mutationKey: [MutationKeys.UPDATE_B2B_ADMIN],
    mutationFn: async ({ b2bAdminId, data }) => {
      return await useUpdateB2BAdminReq(b2bAdminId, { ...data });
    },
    ...options,
    onSuccess: (data, variables, cx) => {
      options?.onSuccess?.(data, variables, cx);

      alert('B2B admin updated successfully...');
    },
    onError: (error: CustomAxiosError, variables, context) => {
      alert(error?.response?.data?.message);
      options?.onError?.(error, variables, context);
    },
  });
};
export default useUpdateB2BAdmin;
