import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import axiosInstance, { CustomAxiosError } from '../../../utils/axios';
import { IErrorResponse } from '../../querys/querys.enum';
import { MutationKeys } from '../../mutation/mutation.enum';
import {
  B2BSubscription,
  B2BSubscriptionUpdateResponse,
  UpdateB2BSubscriptionVariables,
} from '../../../models/Interface/B2BSubscription.interface';

const useUpdateB2BSubscriptionReq = async (
  subscriptionId: string,
  data: Partial<B2BSubscription>
) => {
  const response = await axiosInstance.put<B2BSubscriptionUpdateResponse>(
    `b2b-subscriptions/update/${subscriptionId}`,
    data
  );

  return response.data;
};

const useUpdateB2BSubscription = (
  options?: Partial<
    UseMutationOptions<
      B2BSubscriptionUpdateResponse,
      AxiosError<IErrorResponse>,
      UpdateB2BSubscriptionVariables
    >
  >
) => {
  return useMutation<
    B2BSubscriptionUpdateResponse,
    AxiosError<IErrorResponse>,
    UpdateB2BSubscriptionVariables
  >({
    mutationKey: [MutationKeys.UPDATE_B2B_SUBSCRIPTION],
    mutationFn: async ({ subscriptionId, data }) => {
      return await useUpdateB2BSubscriptionReq(subscriptionId, { ...data });
    },
    ...options,
    onSuccess: (data, variables, cx) => {
      options?.onSuccess?.(data, variables, cx);

      alert('B2B Subscription updated successfully...');
    },
    onError: (error: CustomAxiosError, variables, context) => {
      alert(error?.response?.data?.message);
      options?.onError?.(error, variables, context);
    },
  });
};
export default useUpdateB2BSubscription;
