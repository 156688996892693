import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import axiosInstance from '../../../utils/axios';
import { IErrorResponse } from '../../querys/querys.enum';
import { MutationKeys } from '../../mutation/mutation.enum';
import {
    IUseUpdateSubscriptionResponse,
    Subscription,
    UpdateSubscriptionVariables
} from "../../../models/Interface/Subscription.interface";

const useUpdateSubscriptionReq = async (
    subscriptionId: string,
    data: Partial<Subscription>
) => {

    if (data.nextBillingDate) {
        data.nextBillingDate = new Date(data.nextBillingDate).getTime() / 1000;
    }

    const response = await axiosInstance.put<IUseUpdateSubscriptionResponse>(
        `subscriptions/update/${subscriptionId}`,
        data
    );

    return response.data;
};

const useUpdateSubscription = (
    options?: Partial<
        UseMutationOptions<
            IUseUpdateSubscriptionResponse,
            AxiosError<IErrorResponse>,
            UpdateSubscriptionVariables
        >
    >
) => {
    return useMutation<
        IUseUpdateSubscriptionResponse,
        AxiosError<IErrorResponse>,
        UpdateSubscriptionVariables
    >({
        mutationKey: [MutationKeys.UPDATE_SUBSCRIPTION],
        mutationFn: async ({ subscriptionId, data }) => {
            return await useUpdateSubscriptionReq(subscriptionId, { ...data });
        },
        ...options,
        onSuccess: (data, variables, cx) => {
            options?.onSuccess?.(data, variables, cx);
        },
    });
};
export default useUpdateSubscription;
