import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import axiosInstance from '../../../utils/axios';
import { defaultQueryOptions, QueryKeys } from '../../querys/querys.enum';
import { IUsersRepsonse } from '../../../models/Interface/User.interface';

export interface IFileterInterface {
  value: string;
  label: string;
  search?: string;
}
export interface IFilters {
  email?: string;
  firstName?: string;
  lastName?: string;
  search?: string;
  id?: number;
  verified?: boolean;
  hadPremium?: boolean;
}

const useUsers = (isEnabled: boolean, params: IFilters) => {
  return useInfiniteQuery<IUsersRepsonse>({
    queryKey: [QueryKeys.USERS, params],
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1, queryKey }) => {
      const d = queryKey[1] as IFilters;

      const email = d.email ? `&email=${encodeURIComponent(d.email)}` : '';
      const search = d.search ? `&search=${encodeURIComponent(d.search)}` : '';
      const id = d.id ? `&id=${encodeURIComponent(d.id)}` : '';
      const verified = d.verified
        ? `&verified=${encodeURIComponent(d.verified)}`
        : '';

      return (
        await axiosInstance.get(
          `/users/filter/main-users?page=${pageParam}${email}${search}${id}${verified}`
        )
      ).data;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.totalPage === lastPage.meta.page) return undefined;
      return lastPage.meta.page + 1;
    },
    ...defaultQueryOptions,
    refetchOnWindowFocus: true,
    initialData: undefined,
    enabled: isEnabled,
    placeholderData: keepPreviousData,
  });
};
export default useUsers;
