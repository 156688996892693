import axiosInstance from '../../../utils/axios';
import { IErrorResponse, QueryKeys } from '../../querys/querys.enum';
import { AxiosError } from 'axios';

import { useAppQuery } from '../../querys/useAppQuery';
import { B2BSubscriptionResponse } from '../../../models/Interface/B2BSubscription.interface';

const useB2BSubscription = (b2bSubscriptionId: string) => {
  return useAppQuery<B2BSubscriptionResponse, AxiosError<IErrorResponse>>({
    queryKey: [
      QueryKeys.B2B_SUBSCRIPTION,
      {
        b2bSubscriptionId,
      },
    ],
    queryFn: async () => {
      return (
        await axiosInstance.get(`/b2b-subscriptions/${b2bSubscriptionId}`)
      ).data;
    },
    enabled: !!b2bSubscriptionId,
    retry: 0,
  });
};
export default useB2BSubscription;
