function formatDate (dateString?: string):string {
    if (!dateString) return '';

    const date = new Date(dateString);

    const formatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
        timeZone: 'UTC',
    });

    const utcDateString = formatter.format(date);

    return utcDateString;
};





export default formatDate;