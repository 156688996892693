import * as Yup from 'yup';

const mainUserSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
  newPassword: Yup.string()
    .matches(
      /^[a-zA-Z0-9!@#$%^&*\-]*$/,
      'Password can only contain letters, numbers, and special characters: !@#$%^&*-'
    )
    .max(64, 'Password must be at most 64 characters')
    .min(8, 'Password must be at least 8 characters'),
  verified: Yup.boolean().default(false),
  subscribeToSpecialOffersPushNotifications: Yup.boolean().default(false),
  subscribeToLearningPushNotifications: Yup.boolean().default(false),
  subscribeToLearningEmails: Yup.boolean().default(false),
});

export default mainUserSchema;
