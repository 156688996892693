import { useFormik } from "formik";
import Button from "../../commons/Button/Button";
import { Dropdown } from "../../commons/Dropdown/Dropdown";
import { FormField } from "../../commons/FormField/FormField";
import InputField from "../../commons/InputField/InputField";
import "../../css/ScrollbarStyles.css";
import TextInput from "../../commons/TextInput/TextInput";

const users = [
    { value: 'Doe', label: '1' },
    { value: 'Smith', label: '2' },
    { value: 'Johnson', label: '3' },
];

const EditTransaction = () => {
    const formik = useFormik({
        initialValues: {
            transactionId: "",
            subscriptionId: "",
            user: "",
            amount: 0,
            taxAmount: 0,
            invoiceId: ""
        },
        onSubmit: () => { },
    });
    return (
        <div className="flex overflow-x-auto px-30 pt-80 pb-20 flex-col bg-light-grey-1 h-full">
            <h2 className="text-dark-grey font-bold text-2xl">Edit Transaction</h2>
            <div className="mt-50 mb-30">
                <form onSubmit={(e) => e.preventDefault()}>
                    <FormField label="Transaction ID">
                        <TextInput
                            customStyles="flex-col w-360"
                            placeholder={""}
                            customInputStyles="h-12 w-full"
                            type="text"
                            onChange={(e) => {
                                formik.handleChange("transactionId")(e.target.value);
                            }}
                            id="EditTransaction.TransactionIdField"
                            value={formik.values.transactionId}
                        />
                    </FormField>
                    <FormField label="Subscription ID">
                        <TextInput
                            customStyles="flex-col w-360"
                            placeholder={""}
                            customInputStyles="h-12 w-full"
                            type="text"
                            onChange={(e) => {
                                formik.handleChange("subscriptionId")(e.target.value);
                            }}
                            id="EditTransaction.SubscriptionIdField"
                            value={formik.values.subscriptionId}
                        />
                    </FormField>
                    <FormField label="User">
                        <TextInput
                            customStyles="flex-col w-360"
                            placeholder={""}
                            customInputStyles="h-12 w-full"
                            type="text"
                            onChange={(e) => {
                                formik.handleChange("user")(e.target.value);
                            }}
                            id="EditTransaction.UserField"
                            value={formik.values.user}
                        />
                    </FormField>
                    <FormField label="Amount">
                        <TextInput
                            customStyles="flex-col w-360"
                            placeholder={""}
                            customInputStyles="h-12 w-full"
                            type="number"
                            onChange={(e) => {
                                formik.handleChange("amount")(e.target.value);
                            }}
                            id="EditTransaction.AmountField"
                            value={formik.values.amount}
                        />
                    </FormField>
                    <FormField label="Currency">
                        <Dropdown options={users} className="w-360" id="EditTransaction.CurrencySelect"/>
                    </FormField>
                    <FormField label="Tax amount">
                        <TextInput
                            customStyles="flex-col w-360"
                            placeholder={""}
                            customInputStyles="h-12 w-full"
                            type="number"
                            onChange={(e) => {
                                formik.handleChange("taxAmount")(e.target.value);
                            }}
                            id="EditTransaction.TaxAmountField"
                            value={formik.values.taxAmount}
                        />
                    </FormField>
                    <FormField label="Invoice ID">
                        <TextInput
                            customStyles="flex-col w-360"
                            placeholder={""}
                            customInputStyles="h-12 w-full"
                            type="text"
                            onChange={(e) => {
                                formik.handleChange("invoiceId")(e.target.value);
                            }}
                            id="EditTransaction.InvoiceIdField"
                            value={formik.values.invoiceId}
                        />
                    </FormField>
                </form>
            </div>
            <div>
                <Button type="main" id="EditTransaction.UpdateButton">Update</Button>
            </div>
        </div>
    );
};

export default EditTransaction;