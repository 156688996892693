import React from 'react';
import Select, {
  components,
  DropdownIndicatorProps,
  StylesConfig,
} from 'react-select';
import { SVGProps } from 'react';

interface DropdownInterface {
  options: { value: string; label: string }[];
  placeholder?: string;
  onChange?: (selectedOption: { value: string; label: string } | null) => void;
  value?: string;
  searchIcon?: boolean;
  notSearchable?: boolean;
  withFlag?: boolean;
  className?: string;
  id?: string;
}

type MyOptionType = {
  label: string;
  value: string;
};

const SearchIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#000"
      d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8Zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6Z"
    />
  </svg>
);

const DropdownIndicator: React.FC<
  DropdownIndicatorProps<MyOptionType, false>
> = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SearchIcon />
      </components.DropdownIndicator>
    )
  );
};

export const DropdownWithSearch: React.FC<DropdownInterface> = ({
  options,
  placeholder,
  onChange,
  value,
  searchIcon,
  notSearchable,
  withFlag,
  className,
  id,
}) => {
  const selectedOption = value
    ? options.find((option) => option.value === value)
    : null;

  const customStyles: StylesConfig<MyOptionType, false> = {
    option: (provided) => ({
      ...provided,
      color: '#000',
      backgroundColor: 'transparent',
      fontSize: '16px',
      boxSizing: 'border-box',
      fontWeight: '500 !important',
    }),
    control: (provided, state) => ({
      ...provided,
      height: window.innerWidth > 1024 ? '60px' : '49px',
      borderRadius: state.menuIsOpen ? '1rem 1rem 0 0' : '1rem',
      fontSize: '16px',
      fontWeight: '500 !important',
      boxShadow: 'none',
      color: '#313131',
      border: '1px solid #DFDFDF',
      borderBottom: state.menuIsOpen ? 'none' : '1px solid #DFDFDF',
      boxSizing: 'border-box',
      padding: withFlag ? '0 10px 0 36px !important' : provided.padding,

      '&:focus': {
        border: '1px solid #DFDFDF',
        borderBottom: 'none',
        outline: 'none',
        borderRadius: '1rem 1rem 0 0',
      },
      '&:hover': {
        border: '1px solid #DFDFDF',
        borderBottom: state.menuIsOpen ? 'none' : '1px solid #DFDFDF',
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      position: 'absolute',
      zIndex: 15,
      fontWeight: '500 !important',
      opacity: state.selectProps.menuIsOpen ? '0' : '1',
      boxSizing: 'border-box',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 20,
      borderRadius: '0 0 1rem 1rem',
      position: 'absolute',
      top: window.innerWidth > 1024 ? '50px' : '40px',
      boxSizing: 'border-box',
      boxShadow: 'none',
      border: '1px solid #DFDFDF',
    }),
    menuList: (provided) => ({
      ...provided,
      boxSizing: 'border-box',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      opacity: state.selectProps.menuIsOpen ? '0' : '1',
      fontWeight: '500 !important',
    }),
    input: (provided) => ({
      ...provided,
      color: '#313131',
      zIndex: 11,
      fontWeight: '500 !important',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
      svg: {
        width: '24px',
        height: '24px',
      },
    }),
  };

  const handleChange = (selectedOption: { value: string; label: string; } | null) => {
    if (onChange) {
      onChange(selectedOption);
    }
  };

  return (
    <Select
      instanceId="LangDropdown"
      placeholder={placeholder}
      options={options}
      styles={customStyles}
      onChange={handleChange}
      value={selectedOption}
      components={searchIcon ? { DropdownIndicator } : undefined}
      noOptionsMessage={() => 'Not found'}
      isSearchable={!notSearchable}
      className={className}
      id={id}
    />
  );
};
