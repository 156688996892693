import axiosInstance from '../../../utils/axios';
import { IErrorResponse, QueryKeys } from '../../querys/querys.enum';
import { AxiosError } from 'axios';
import { useAppQuery } from '../../querys/useAppQuery';
import {
  IOrganizationResponse,
  IOrganizationUpdateResponse,
} from '../../../models/Interface/Organization.interface';

const useOrganization = (orgId: string) => {
  return useAppQuery<IOrganizationResponse, AxiosError<IErrorResponse>>({
    queryKey: [
      QueryKeys.ORGANIZATION,
      {
        orgId,
      },
    ],
    queryFn: async () => {
      return await axiosInstance.get(`/organizations/${orgId}`);
    },
    enabled: !!orgId,
    retry: 0,
  });
};
export default useOrganization;
