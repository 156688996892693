import Typography from '../../commons/Typography/Typography';
import '../../css/ScrollbarStyles.css';
import { useNavigate } from 'react-router';

import { Fragment, useEffect, useState } from 'react';
import { FiltersComponent } from '../../commons/FiltersComponent/FiltersComponent';
import FormatDate from '../../utils/helpers/formatDate';
import useB2BSubscriptions, {
  IFilters,
} from '../../state/hooks/b2b-subscriptions/useB2BSubscriptions';

const filterOptions = [
  { value: 'subscriptionId', label: 'B2B Subscription ID' },
  { value: 'adminEmail', label: 'Admin Email' },
  { value: 'organizationName', label: 'Organization Name' },
  { value: 'orgId', label: 'Organization ID' },
];

const B2BSubscriptions = () => {
  const [filtersCount, setFiltersCount] = useState<IFilters>({});

  const {
    data,
    isLoading,
    isError,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useB2BSubscriptions(true, filtersCount);

  useEffect(() => {
    addFilter();
  }, []);

  const addFilter = () => {
    const newFilter = filterOptions.find(
      (option) => !Object.keys(filtersCount).includes(option.value)
    );
    if (newFilter) {
      setFiltersCount((prevFilters) => ({
        ...prevFilters,
        [newFilter.value]: '',
      }));
    }
  };

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;

    if (bottom) {
      if (isFetchingNextPage && hasNextPage) return;
      fetchNextPage();
    }
  };
  return (
    <div className="flex px-30 pt-55 pb-20 flex-col bg-light-grey-1 h-full w-full">
      <h2 className="text-dark-grey font-bold text-2xl mb-30">
        B2B Subscriptions
      </h2>
      <FiltersComponent
        setFiltersCount={setFiltersCount}
        filterOptions={filterOptions} // Pass filterOptions as prop
        filtersCount={filtersCount}
      />
      <div
        className="overflow-x-auto rounded-xl border-light-grey-3 border-solid border"
        onScroll={handleScroll}
      >
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>{JSON.stringify(error)}</div>
        ) : (
          <table className="table-auto w-full overflow-x-auto bg-white rounded-xl border-collapse text-dark-grey">
            <thead className="sticky top-0">
              <tr className="bg-gray-100 h-48 bg-light-grey-4">
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Organization name</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Organization ID</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Admin email</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Package</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">License Count</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Creation Date</Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.pages.map((b2bSubscriptionList) => (
                <Fragment key={b2bSubscriptionList.meta.page}>
                  {b2bSubscriptionList?.data?.map((b2bSubscription) => (
                    <tr
                      className="cursor-pointer border-light-grey-3 border-solid border-t hover:bg-light-grey-1"
                      key={b2bSubscription.id}
                      onClick={() => {
                        const url = `/b2b-subscriptions/${b2bSubscription.id}`;
                        window.open(url, '_blank');
                      }}
                    >
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {b2bSubscription.organization.organizationName}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {b2bSubscription.organization.id}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {b2bSubscription.organization.b2bAdmins
                            ? b2bSubscription.organization.b2bAdmins
                                .map((admin) => admin.email)
                                .join(', ')
                            : 'No admins available'}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {b2bSubscription.package}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {b2bSubscription.licenseCount}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {FormatDate(b2bSubscription.createdAt) || ''}
                        </Typography>
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default B2BSubscriptions;
