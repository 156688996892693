import React from "react";
import ReactDOM from "react-dom/client";
import './index.css';
import Wrappers from "./HOC/Wrappers";
// import 'dotenv/config'

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Wrappers />
  </React.StrictMode>
);
