import axiosInstance from '../../../utils/axios';
import { IErrorResponse, QueryKeys } from '../../querys/querys.enum';
import { AxiosError } from 'axios';
import { ISubUserResponse } from '../../../models/Interface/User.interface';
import { useAppQuery } from '../../querys/useAppQuery';

const useSubUser = (userId: string | undefined) => {
  return useAppQuery<ISubUserResponse, AxiosError<IErrorResponse>>({
    queryKey: [
      QueryKeys.SUB_USER,
      {
        userId,
      },
    ],
    queryFn: async () => {
      return (await axiosInstance.get(`/users/sub/${userId}`)).data;
    },
    enabled: !!userId,
    retry: 0,
  });
};
export default useSubUser;
