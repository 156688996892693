import { useAppQuery } from '../../querys/useAppQuery';
import { AxiosError } from 'axios';
import { IErrorResponse, QueryKeys } from '../../querys/querys.enum';
import { IAppParamsDataResponse } from './interface/AppParams.interface';
import axiosInstance from '../../../utils/axios';

export const useAppParamsFunc = async () => {
  const response = await axiosInstance.get<IAppParamsDataResponse>(
    'app-params'
  );
  return response.data;
};

const useAppParams = () => {
  return useAppQuery<IAppParamsDataResponse, AxiosError<IErrorResponse>>({
    queryKey: [QueryKeys.APP_PARAMS],
    queryFn: () => {
      return useAppParamsFunc();
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    refetchInterval: 1000 * 60 * 10, // 10 minutes
    staleTime: Infinity,
    gcTime: 1000 * 60 * 60 * 24, // 24 hours
  });
};

export default useAppParams;
