import { useLocation } from 'react-router-dom';
import { Formik, FormikValues } from 'formik';
import Button from '../../commons/Button/Button';
import { FormField } from '../../commons/FormField/FormField';
import '../../css/ScrollbarStyles.css';
import useSubscription from '../../state/hooks/subscriptions/useSubscription';
import TextInput from '../../commons/TextInput/TextInput';
import Typography from '../../commons/Typography/Typography';
import useMe from '../../state/hooks/auth/useMe';
import { DropdownWithSearch } from '../../commons/Dropdown';
import getChangedValues from '../../utils/helpers/getChangedValues';
import nextBillingDate from '../../utils/helpers/nextBillingDate';
import formatDate from '../../utils/helpers/formatDate';
import useUpdateOrganization from '../../state/hooks/organizations/useUpdateOrganization';
import useOrganization from '../../state/hooks/organizations/useOrganization';

const Organization = () => {
  const query = useLocation();
  const orgId = query.pathname.replace('/organizations/', '');
  const { data: meData, isLoading: mLoading } = useMe();
  const { mutate, isPending: UPending } = useUpdateOrganization({
    onSuccess: (data, variables, context) => {
      console.warn('Organization update success');
    },
  });

  const {
    data: SData,
    isLoading,
    isPending,
    isError,
    error,
    isFetching,
  } = useOrganization(orgId);

  if (isLoading || isPending || mLoading || isFetching)
    return <div>Loading...</div>;

  if (isError && error)
    return (
      <div className="flex flex-1 justify-center items-center h-full">
        <Typography>{JSON.stringify(error.response?.data.message)}</Typography>
      </div>
    );

  const initialValues = {
    id: SData.data?.id || '',
    createdAt: SData?.data?.createdAt || '',
    billingAddress: SData?.data?.billingAddress || '',
    active: SData?.data?.active || false,
    city: SData?.data?.city || '',
    country: SData?.data?.country || '',
    state: SData?.data?.state || '',
    totalLicenses: SData?.data?.totalLicenses || '',
    organizationAdmin: SData?.data?.b2bAdmins?.[0]?.email || '',
    postalCode: SData?.data?.postalCode || '',
    organizationName: SData?.data?.organizationName || '',
  };

  const handleSubmit = async (values: any) => {
    const changedValues = getChangedValues(initialValues, values);

    if (Object.keys(changedValues).length > 0) {
      mutate({ orgId, data: changedValues });
    }
  };

  return (
    <div className="flex overflow-x-auto px-30 pt-80 pb-20 flex-col bg-light-grey-1 h-full">
      <h2 className="text-dark-grey font-bold text-2xl">Organization</h2>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik: FormikValues) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-50 mb-30">
              <FormField label="ID">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="Organization.id"
                  defaultValue={formik.values.id}
                  name="id"
                  disabled
                />
              </FormField>
              <FormField label="Organization Name">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('organizationName', e.target.value)
                  }
                  id="Organization.organizationName"
                  defaultValue={formik.values.organizationName}
                  name="organizationName"
                />
              </FormField>
              <FormField label="Billing Address">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder="Billing Address"
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('billingAddress', e.target.value)
                  }
                  id="Organization.billingAddress"
                  defaultValue={formik.values.billingAddress}
                  name="billingAddress"
                />
              </FormField>
              <FormField label="Active">
                <input
                  type="checkbox"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  checked={formik.values.active}
                  name="active"
                  id="Organization.active"
                />
              </FormField>
              <FormField label="City">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder="City"
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) => formik.setFieldValue('city', e.target.value)}
                  id="Organization.city"
                  defaultValue={formik.values.city}
                  name="city"
                />
              </FormField>
              <FormField label="Country">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder="Country"
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('country', e.target.value)
                  }
                  id="Organization.country"
                  defaultValue={formik.values.country}
                  name="country"
                />
              </FormField>
              <FormField label="State">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder="State"
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('state', e.target.value)
                  }
                  id="Organization.state"
                  defaultValue={formik.values.state}
                  name="state"
                />
              </FormField>
              <FormField label="Total Licenses">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder="Total Licenses"
                  customInputStyles="h-12 w-full"
                  type="number"
                  min="0"
                  onChange={(e) =>
                    formik.setFieldValue('totalLicenses', +e?.target?.value)
                  }
                  id="Organization.totalLicenses"
                  defaultValue={formik.values.totalLicenses}
                  name="totalLicenses"
                />
              </FormField>
              <FormField label="Organization Admin">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder="Organization Admin Email"
                  customInputStyles="h-12 w-full"
                  type="email"
                  onChange={formik.handleChange}
                  id="Organization.organizationAdmin"
                  defaultValue={formik.values.organizationAdmin}
                  name="organizationAdmin"
                  disabled
                />
              </FormField>
              <FormField label="Zip Code">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder="Postal Code"
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue('postalCode', e.target.value)
                  }
                  id="Organization.postalCode"
                  defaultValue={formik.values.postalCode}
                  name="postalCode"
                />
              </FormField>
            </div>
            <div>
              <Button
                type="main"
                id="Subscription.UpdateButton"
                onClick={() => handleSubmit(formik.values)}
                isLoading={UPending}
              >
                Update
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Organization;
