import {decodeToken} from "./cookie.settings";
type StorageType = 'session' | 'local';


export const LOCAL_STORAGE = 'local'

export const SESSION_STORAGE = 'session'

const storageType = (type?: StorageType): any =>  `${type ?? 'session'}Storage`;

const isBrowser: boolean = ((): boolean => typeof window !== 'undefined')();

function isStringRecord(obj: any): obj is { [key: string]: string } {
    return typeof obj === 'object' && obj !== null;
}

export const getItem = (key: string, type: StorageType): string => {
    if (isBrowser) {
        const storage = window[storageType(type)] || {};
        if (isStringRecord(storage)) {
            return storage[key] || '';
        }
    }
    return '';
}

export const setItem = (key: string, value: string, type: StorageType): boolean => {
    if (isBrowser) {
        const storage = window[storageType(type)] as unknown as Storage | null;
        if (storage) {
            storage.setItem(key, value);
            return true;
        }
    }

    return false;
};

export const getUserId = () => {
    const token:string = getItem("token", LOCAL_STORAGE)


    return decodeToken(token)?.sub
}

export const clearStorage = (type: StorageType): void => {
    const storage = window[storageType(type)] as unknown as Storage | null;
    if (storage) {
        storage.clear();
    }

}


export const removeItem = (key: string, type: StorageType): void => {
    const storage = window[storageType(type)] as unknown as Storage | null;
    if (storage) {
        storage.removeItem(key);
    }
};

