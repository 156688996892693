import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError as OriginalAxiosError } from 'axios';
import { queryClient } from '../state';
import { clearStorage, LOCAL_STORAGE, setItem } from "./settings/storage.settings";

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
    _retry?: boolean;
}

interface CustomErrorData {
    message: string;
}

export interface CustomAxiosError<T = any> extends OriginalAxiosError<T> {
    response?: AxiosResponse<T> | undefined | any;
}

class AxiosError extends Error implements CustomAxiosError {
    config: any;
    code?: string;
    request?: any;
    response?: {
        data: CustomErrorData;
    };
    isAxiosError: boolean;
    toJSON: () => object;

    constructor(error: OriginalAxiosError<CustomErrorData>) {
        super(error.message);
        this.name = 'AxiosError';
        this.config = error.config;
        this.code = error.code;
        this.request = error.request;
        this.response = error.response;
        this.isAxiosError = error.isAxiosError;
        this.toJSON = error.toJSON;
    }

    status?: number | undefined;
    cause?: Error | undefined;
    name: string;
    stack?: string | undefined;

}


const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        config.headers['source'] = 'web';
        const token = window.localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

const refreshToken = async () => {
    try {
        const refresh = window.localStorage.getItem('refreshToken');
        const response = await axiosInstance.post(`/auth/refresh-token/${refresh}`,)
        const { token } = response.data;
        setItem('token', token.accessToken, LOCAL_STORAGE);
        setItem('refreshToken', token.refreshToken, LOCAL_STORAGE);
        return token.accessToken;
    } catch (error) {
        throw new Error('Unable to refresh token');
    }
};


let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach(prom => {
        if (token) {
            prom.resolve(token);
        } else {
            prom.reject(error);
        }
    });
    failedQueue = [];
};

// Response interceptor
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    async (error: AxiosError) => {
        const originalRequest: ExtendedAxiosRequestConfig = error.config!;

        if (error.config?.url.includes('/auth/refresh-token')) {
            queryClient.clear();
            clearStorage(LOCAL_STORAGE);
            window.location.href = '/';
        }

        // @ts-ignore
        if (error.response?.status === 401 && error.response?.data.message === 'Unauthorized') {
            queryClient.clear();
            clearStorage(LOCAL_STORAGE);
            window.location.href = '/';
        }

        // @ts-ignore
        if (error.response?.status === 401 && (error.response?.data.name === 'TokenExpiredError' || error.response.data.name === 'JsonWebTokenError') && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers = originalRequest.headers || {};
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return axiosInstance(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise((resolve, reject) => {
                refreshToken().then(token => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                    originalRequest.headers = originalRequest.headers || {};
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    processQueue(null, token);
                    resolve(axiosInstance(originalRequest));
                }).catch((err) => {
                    processQueue(err, null);
                    reject(err);
                }).finally(() => { isRefreshing = false; });
            });
        }

        return Promise.reject(error);
    }
);


export default axiosInstance;