import { mapLabelToKey } from "../../utils/helpers/labelToKey";
import { ISubUserResponse } from "../Interface/User.interface";
import { IAppParamsDataResponse } from "../../state/hooks/app/interface/AppParams.interface";


const SubUserInitialValues = (values: { UData: ISubUserResponse, AppData: IAppParamsDataResponse }) => {

    const { UData, AppData } = values;


    return {
        firstName: UData?.data?.firstName || '',
        lastName: UData?.data?.lastName || '',
        email: UData?.data?.email || '',
        createdAt: UData?.data?.createdAt || '',
        source: UData?.data?.source || '',
        package: UData?.data?.package || '',
        targetLanguage: UData?.data?.targetLanguage || '',
        currentLevel: UData?.data?.currentLevel || '',
        primaryGoal: UData?.data?.primaryGoal || '',
        nativeLanguage: UData?.data?.nativeLanguage || '',
        practiceFrequency:
            mapLabelToKey(
                UData?.data?.practiceFrequency,
                AppData?.data?.frequencies
            ) || '',
        mute: UData?.data?.mute || false,
        sentenceModeMute: UData?.data?.sentenceMute || false,
        hadPremium: UData?.data?.hadPremium || false,
        interfaceLanguage: UData?.data?.interfaceLanguage || '',
        isSuggest: UData?.data?.isSuggest || false,
        isHandsFree: UData?.data?.isHandsFree || false,
        isHandsFreeCallMode: UData?.data?.isHandsFreeCallMode || false,
        isHandsFreeSentenceMode: UData?.data?.isHandsFreeSentenceMode || false,
        isDarkMode: UData?.data?.isDarkMode || false,
        chatName: UData?.data?.chatName || '',
        speechSpeed: UData?.data?.speechSpeed || 1,
        newsletter: UData?.data?.newsletter || false,
        abTestingVersion: UData?.data?.abTestingVersion || '',
        timeZone: UData?.data?.timeZone || 'UTC+00:00',
        handsFreeInterval: UData?.data?.handsFreeInterval || 1,
        handsFreeIntervalCallMode: UData?.data?.handsFreeIntervalCallMode || 1,
        handsFreeIntervalSentenceMode: UData?.data?.handsFreeIntervalSentenceMode || 1,
        fontSize: UData?.data?.fontSize || 0,
        premium: UData?.data?.premium || false,
        isTestUser: UData?.data?.isTestUser || false,
        abTesting: UData?.data?.abTesting || {
            abTestingVersion: '',
            abTestingVersionSlot2: '',
            abTestingVersionSlot3: '',
            abTestingVersionSlot4: '',
            abTestingVersionSlot5: '',
        },
    };

}

export default SubUserInitialValues;
