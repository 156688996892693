import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axiosInstance from '../../../../utils/axios';
import { useAppQuery } from '../../../querys/useAppQuery';
import { AxiosError } from 'axios';
import { IErrorResponse, QueryKeys } from '../../../querys/querys.enum';
import { LanguageResponse } from '../Interface/Language.interface';

export const UseLanguageReq = async () => {
  const response = await axiosInstance.get<LanguageResponse>('languages/list');

  return response.data;
};
const useLanguage = () => {
  return useAppQuery<LanguageResponse, AxiosError<IErrorResponse>>({
    queryKey: [QueryKeys.LANGUAGES],
    queryFn: () => {
      return UseLanguageReq();
    },
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
    refetchInterval: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 10, // 10 minutes
    retry: 3,
  });
};

export default useLanguage;
