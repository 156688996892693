import * as Yup from 'yup';

const newPromptSchema = Yup.object({
  promptType: Yup.string().required('Required'),
  unifiedPromptMessage: Yup.string().nullable().default(''),
  beginnerPromptMessage: Yup.string().nullable().default(''),
  intermediatePromptMessage: Yup.string().nullable().default(''),
  advancedPromptMessage: Yup.string().nullable().default(''),
  suggestedAnswerPrompt: Yup.string().nullable().default(''),
  firstMessage: Yup.string().nullable().default(''),
});

export default newPromptSchema;
