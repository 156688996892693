import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import axiosInstance, { CustomAxiosError } from '../../../utils/axios';
import { IErrorResponse } from '../../querys/querys.enum';
import { MutationKeys } from '../../mutation/mutation.enum';
import {
  IPromptUpdateResponse,
  Prompt,
  UpdatePromptVariables,
} from '../../../models/Interface/Prompt.interface';

const useUpdatePromptReq = async (
  promptType: string,
  data: Partial<Prompt>
) => {
  const response = await axiosInstance.put<IPromptUpdateResponse>(
    `prompts/${promptType}`,
    data
  );

  return response.data;
};

const useUpdatePrompt = (
  options?: Partial<
    UseMutationOptions<
      IPromptUpdateResponse,
      AxiosError<IErrorResponse>,
      UpdatePromptVariables
    >
  >
) => {
  return useMutation<
    IPromptUpdateResponse,
    AxiosError<IErrorResponse>,
    UpdatePromptVariables
  >({
    mutationKey: [MutationKeys.UPDATE_PROMPT],
    mutationFn: async ({ promptType, data }) => {
      return await useUpdatePromptReq(promptType, { ...data });
    },
    ...options,
    onSuccess: (data, variables, cx) => {
      options?.onSuccess?.(data, variables, cx);

      alert(data.message);
    },
    onError: (error: CustomAxiosError, variables, context) => {
      alert(error?.response?.data?.message);
      options?.onError?.(error, variables, context);
    },
  });
};
export default useUpdatePrompt;
