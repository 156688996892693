import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { Formik, FormikValues } from 'formik';
import Button from '../../commons/Button/Button';
import { FormField } from '../../commons/FormField/FormField';
import '../../css/ScrollbarStyles.css';
import useUser from '../../state/hooks/users/useUser';
import TextInput from '../../commons/TextInput/TextInput';
import Typography from '../../commons/Typography/Typography';
import useMe from '../../state/hooks/auth/useMe';
import useUpdateUser from '../../state/hooks/users/useUpdateUser';
import { InputErrorMessage } from '../../commons/error';
import generateRandomPassword from '../../utils/helpers/generatePassword';
import mainUserSchema from '../../models/schemas/main-user.schema';
import useDeleteMainUser from '../../state/hooks/users/useDeleteMainUser';;
import getChangedValues from '../../utils/helpers/getChangedValues';
import formatDate from "../../utils/helpers/formatDate";

const Profile = () => {
  const query = useLocation();
  const userId = query.pathname.replace('/main-users/', '');
  const { data: meData, isLoading: mLoading } = useMe();
  const { mutate, isPending: UPending } = useUpdateUser({
    onSuccess: () => {
      setShowPassword(false);
      setNewPassword('');
      refetch();
    },
  });

  const {
    refetch,
    data: UData,
    isLoading,
    isPending,
    isError,
    error,
    isFetching,
  } = useUser(userId);

  const { mutateDeleteMainUser, isPending: UDeletedPending } =
    useDeleteMainUser({
      onSuccess: () => {
        alert('Deleted MainUser success');
        refetch();
      },
      onError: (error) => {
        alert(`Failed to delete MainUser: ${error}`);
      },
    });

  const handleDeleteMainUser = () => {
    const confirmDelete = confirm('Are you sure you want to delete this user?');

    if (confirmDelete) {
      mutateDeleteMainUser({ mainUserId: userId });
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  if (isLoading || isPending || mLoading || isFetching)
    return <div>Loading...</div>;

  if (isError && error) {
    return (
      <div className="flex flex-1 justify-center items-center h-full">
        <Typography>{JSON.stringify(error.response?.data.message)}</Typography>
      </div>
    );
  }



  const initialValues = {
    email: UData?.email || '',
    verified: UData?.verified || false,
    newsletter: UData?.newsletter || false,
    subscribeToSpecialOffersPushNotifications: UData?.subscribeToSpecialOffersPushNotifications || false,
    subscribeToLearningEmails: UData?.subscribeToLearningEmails || false,
    subscribeToLearningPushNotifications: UData?.subscribeToLearningPushNotifications || false,
    newPassword: '',
    createdAt: formatDate(UData?.createdAt),
    verificationDate: formatDate(UData?.verificationDate),
  };

  const handleSubmit = async (values: any) => {
    console.log('values', values);
    const { newPassword, ...rest } = getChangedValues(initialValues, values);

    if (Object.keys({ newPassword, ...rest }).length > 0) {
      mutate({
        userId,
        data: { ...rest, password: newPassword },
      });
    }
  };

  const handleGeneratePassword = (
    setFieldValue: (arg0: string, arg1: string) => void
  ) => {
    const password = generateRandomPassword();
    setNewPassword(password);
    setFieldValue('newPassword', password);
    setShowPassword(true);
    setPasswordFieldType('text'); // Show the generated password as text initially
  };

  return (
    <div className="flex overflow-x-auto px-30 pt-80 pb-20 flex-col bg-light-grey-1 h-full">
      <h2 className="text-dark-grey font-bold text-2xl">Profile</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={mainUserSchema}
        onSubmit={handleSubmit}
      >
        {(formik: FormikValues) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-50 mb-30">
              <FormField label="Email (required)">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="email"
                  onChange={(e) =>
                    formik.setFieldValue('email', e.target.value)
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  name="email"
                />
                {InputErrorMessage({ props: formik, type: 'email' })}
              </FormField>
              <FormField label="Creation Date">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  onChange={(e) =>
                    formik.setFieldValue('creationDate', e.target.value)
                  }
                  type="text"
                  onBlur={formik.handleBlur}
                  value={formik.values.createdAt}
                  name="creationDate"
                  id="Profile.CreationDate"
                  disabled
                />
              </FormField>
              <FormField label="Verification Date">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  onChange={(e) =>
                    formik.setFieldValue('verificationDate', e.target.value)
                  }
                  type="text"
                  onBlur={formik.handleBlur}
                  value={formik.values.verificationDate}
                  name="verificationDate"
                  id="Profile.VerificationDate"
                  disabled
                />
              </FormField>
              <FormField label="Verified">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    formik.setFieldValue('verified', e.target.checked)
                  }
                  onBlur={formik.handleBlur}
                  checked={formik.values.verified}
                  name="verified"
                  id="Profile.Verified"
                />
              </FormField>
              <FormField label="NewsLetter">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    formik.setFieldValue('newsletter', e.target.checked)
                  }
                  onBlur={formik.handleBlur}
                  checked={formik.values.newsletter}
                  name="newsletter"
                  id="Profile.NewsLetter"
                />
              </FormField>
              <FormField label="SubscribeToLearningEmails">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    formik.setFieldValue('subscribeToLearningEmails', e.target.checked)
                  }
                  onBlur={formik.handleBlur}
                  checked={formik.values.subscribeToLearningEmails}
                  name="subscribeToLearningEmails"
                  id="Profile.SubscribeToLearningEmails"
                />
              </FormField>
              <FormField label="SubscribeToLearningPushNotifications">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    formik.setFieldValue('subscribeToLearningPushNotifications', e.target.checked)
                  }
                  onBlur={formik.handleBlur}
                  checked={formik.values.subscribeToLearningPushNotifications}
                  name="subscribeToLearningPushNotifications"
                  id="Profile.SubscribeToLearningPushNotifications"
                />
              </FormField>
              <FormField label="subscribeToSpecialOffersPushNotifications">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    formik.setFieldValue('subscribeToSpecialOffersPushNotifications', e.target.checked)
                  }
                  onBlur={formik.handleBlur}
                  checked={formik.values.subscribeToSpecialOffersPushNotifications}
                  name="subscribeToSpecialOffersPushNotifications"
                  id="Profile.SubscribeToSpecialOffersPushNotifications"
                />
              </FormField>
              <FormField label="Set New Password">
                <Button
                  type="secondary"
                  buttonType="button"
                  onClick={() => handleGeneratePassword(formik.setFieldValue)}
                >
                  Set New Password
                </Button>
                {showPassword && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '10px',
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                      }}
                    >
                      <TextInput
                        customStyles="flex-col w-360"
                        placeholder=""
                        customInputStyles="h-12 w-full"
                        type={passwordFieldType}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                          formik.setFieldValue('newPassword', e.target.value);
                        }}
                        value={formik.values.newPassword || newPassword}
                        name="newPassword"
                      />
                      <div style={{ position: 'absolute', bottom: '-20px' }}>
                        {InputErrorMessage({
                          props: formik,
                          type: 'newPassword',
                        })}
                      </div>
                    </div>
                    <div style={{ display: 'flex', marginLeft: '10px' }}>
                      <Button
                        type="secondary"
                        buttonType="button"
                        onClick={() =>
                          setPasswordFieldType(
                            passwordFieldType === 'text' ? 'password' : 'text'
                          )
                        }
                        style={{ marginLeft: '10px' }}
                      >
                        {passwordFieldType === 'text' ? 'Hide' : 'Show'}
                      </Button>
                      <Button
                        type="secondary"
                        buttonType="button"
                        onClick={() => {
                          setNewPassword('');
                          setShowPassword(false);
                          formik.setFieldValue('newPassword', '');
                        }}
                        style={{ marginLeft: '10px' }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
              </FormField>
              <FormField label="Delete user">
                <Button
                  type="secondary"
                  buttonType="button"
                  id="Profile.DeleteUserButton"
                  disabled={formik.values.email
                    .toLowerCase()
                    .includes('anonymous')}
                  isLoading={UDeletedPending}
                  onClick={handleDeleteMainUser}
                >
                  Delete
                </Button>
              </FormField>
            </div>
            <div>
              <Button
                type="main"
                id="Profile.UpdateButton"
                isLoading={UPending}
                buttonType="submit"
              >
                Update
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Profile;
