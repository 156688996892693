import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import axiosInstance from "../../../utils/axios";
import {clearStorage, getItem, LOCAL_STORAGE} from "../../../utils/settings/storage.settings";
import {MutationKeys} from "../../mutation/mutation.enum";

const useLogout = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation<void, Error, void>({
        mutationFn: async () => {
            const token = getItem('token', LOCAL_STORAGE);
            if (!token) {
                return Promise.reject({ message: 'No token found', response: { status: 401 } });
            }

            const res = await axiosInstance.post('/auth/log-out');

            return res.data;
        },
        onSuccess: () => {
            clearStorage(LOCAL_STORAGE);
            queryClient.clear();
            navigate('/');
            queryClient.clear();
        },
        onError: (error) => {
            console.error('Logout failed:', error);
        },
        mutationKey:[MutationKeys.LOGOUT]
    });
}

export default useLogout