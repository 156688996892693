import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  IUseUpdateUserCredentials,
  IUseUpdateUserResponse,
  UpdateUserVariables,
} from '../../../models/Interface/User.interface';
import axiosInstance, { CustomAxiosError } from '../../../utils/axios';
import { IErrorResponse, QueryKeys } from '../../querys/querys.enum';
import { MutationKeys } from '../../mutation/mutation.enum';

const useUpdateSubUserReq = async (
  userId: string,
  data: Partial<IUseUpdateUserCredentials>
) => {
  const response = await axiosInstance.put<IUseUpdateUserResponse>(
    `users/update/sub/${userId}`,
    data
  );

  return response.data;
};

const useUpdateSubUser = (
  options?: Partial<
    UseMutationOptions<
      IUseUpdateUserResponse,
      AxiosError<IErrorResponse>,
      UpdateUserVariables
    >
  >
) => {
  const queryClient = useQueryClient();
  return useMutation<
    IUseUpdateUserResponse,
    AxiosError<IErrorResponse>,
    UpdateUserVariables
  >({
    mutationKey: [MutationKeys.UPDATE_SUB_USER],
    mutationFn: async ({ userId, data }) => {
      return await useUpdateSubUserReq(userId, { ...data });
    },
    ...options,
    onSuccess: (data, variables, cx) => {
      queryClient.setQueryData([QueryKeys.SUB_USER], data.user);
      options?.onSuccess?.(data, variables, cx);

      alert('SubUser updated successfully...');
    },
    onError: (error: CustomAxiosError, variables, context) => {
      alert(error?.response?.data?.message);
      options?.onError?.(error, variables, context);
    },
  });
};
export default useUpdateSubUser;
