import axiosInstance from "../../../utils/axios";

const useUserExport = async (enabled = false) => {
    const response = await axiosInstance.get(`/users/generate/report`)

    return response.data;

};

export default useUserExport;

