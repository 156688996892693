import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../commons/Navbar/Navbar";
import useMe from "../state/hooks/auth/useMe";
import Spinner from "../commons/Spinner/Spinner";
enum Permissions {
  Dashboard = "permission.dashboard",
  Settings = "permission.settings",
  // Add more permissions as needed
}

const WithPermission = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data, isSuccess, isLoading, isError, error } = useMe();

  if (isError) {
    window.localStorage.clear();
    queryClient.clear();
    navigate("/");
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        height: "100vh",
        width: "100vw",
      }}
    >
      <Navbar />
      <div
        style={{
          backgroundColor: "#F5F6FA",
          height: "100vh",
          flexGrow: 1,
        }}
      >
        {isSuccess && data ? (
          <Outlet />
        ) : (
          <div className="flex flex-1 justify-center items-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};
export default WithPermission;
