import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, FormikValues } from 'formik';
import Button from '../../commons/Button/Button';
import Typography from '../../commons/Typography/Typography';
import '../../css/ScrollbarStyles.css';
import usePrompt from '../../state/hooks/prompts/usePrompt';
import formatPromptType from '../../utils/helpers/formatPrompt';
import singlePromptSchema from '../../models/schemas/single-prompt.schema';
import { InputErrorMessage } from '../../commons/error';
import useUpdatePrompt from '../../state/hooks/prompts/useUpdatePrompt';

const SinglePrompt = () => {
  const query = useLocation();
  const promptType = query.pathname.replace('/prompts/', '');
  const { data, isLoading, isError, isFetching, isPending } =
    usePrompt(promptType);
  const { mutate, isPending: PPending } = useUpdatePrompt({
    onSuccess: (data, variables, context) => {
      console.warn('Prompt update success');
    },
  });

  const initialValues = {
    unifiedPromptMessage: '',
    beginnerPromptMessage: '',
    intermediatePromptMessage: '',
    advancedPromptMessage: '',
    suggestedAnswerPrompt: '',
    firstMessage: '',
  };

  useEffect(() => {
    if (data) {
      const textareas = document.querySelectorAll('textarea');
      textareas.forEach((textarea) => {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
      });
    }
  }, [data]);

  const adjustTextareaHeight = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  if (isLoading || isFetching || isPending) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading prompt</div>;
  }

  const handleSubmit = async (values: any) => {
    mutate({ promptType, data: values });
  };

  return (
    <div className="flex px-30 pt-55 pb-20 flex-col bg-light-grey-1 h-full overflow-auto">
      <h2 className="text-dark-grey font-bold text-2xl mb-50">
        {formatPromptType(data.promptType)}
      </h2>
      <Formik
        initialValues={data ? data : initialValues}
        validationSchema={singlePromptSchema}
        onSubmit={handleSubmit}
      >
        {(formik: FormikValues) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="w-712 flex flex-col gap-10 mb-50">
              <Typography weight="bold">Unified prompt</Typography>
              <textarea
                className="w-full border border-light-grey-3 rounded-md focus:outline-none text-sm font-semibold px-20 py-10 resize-none overflow-hidden"
                name="unifiedPromptMessage"
                value={formik.values.unifiedPromptMessage}
                onChange={(e) => {
                  formik.handleChange(e);
                  adjustTextareaHeight(e);
                }}
                onBlur={formik.handleBlur}
                rows={1}
              ></textarea>
              {InputErrorMessage({
                props: formik,
                type: 'unifiedPromptMessage',
              })}
            </div>
            <div className="w-712 flex flex-col gap-10 mb-50">
              <Typography weight="bold">Beginner prompt</Typography>
              <textarea
                className="w-full border border-light-grey-3 rounded-md focus:outline-none text-sm font-semibold px-20 py-10 resize-none overflow-hidden"
                name="beginnerPromptMessage"
                value={formik.values.beginnerPromptMessage}
                onChange={(e) => {
                  formik.handleChange(e);
                  adjustTextareaHeight(e);
                }}
                onBlur={formik.handleBlur}
                rows={1}
              ></textarea>
              {InputErrorMessage({
                props: formik,
                type: 'beginnerPromptMessage',
              })}
            </div>
            <div className="w-712 flex flex-col gap-10 mb-50">
              <Typography weight="bold">Intermediate prompt</Typography>
              <textarea
                className="w-full border border-light-grey-3 rounded-md focus:outline-none text-sm font-semibold px-20 py-10 resize-none overflow-hidden"
                name="intermediatePromptMessage"
                value={formik.values.intermediatePromptMessage}
                onChange={(e) => {
                  formik.handleChange(e);
                  adjustTextareaHeight(e);
                }}
                onBlur={formik.handleBlur}
                rows={1}
              ></textarea>
              {InputErrorMessage({
                props: formik,
                type: 'intermediatePromptMessage',
              })}
            </div>
            <div className="w-712 flex flex-col gap-10 mb-50">
              <Typography weight="bold">Advanced prompt</Typography>
              <textarea
                className="w-full border border-light-grey-3 rounded-md focus:outline-none text-sm font-semibold px-20 py-10 resize-none overflow-hidden"
                name="advancedPromptMessage"
                value={formik.values.advancedPromptMessage}
                onChange={(e) => {
                  formik.handleChange(e);
                  adjustTextareaHeight(e);
                }}
                onBlur={formik.handleBlur}
                rows={1}
              ></textarea>
              {InputErrorMessage({
                props: formik,
                type: 'advancedPromptMessage',
              })}
            </div>
            <div className="w-712 flex flex-col gap-10 mb-50">
              <Typography weight="bold">Suggested answer prompt</Typography>
              <textarea
                className="w-full border border-light-grey-3 rounded-md focus:outline-none text-sm font-semibold px-20 py-10 resize-none overflow-hidden"
                name="suggestedAnswerPrompt"
                value={formik.values.suggestedAnswerPrompt}
                onChange={(e) => {
                  formik.handleChange(e);
                  adjustTextareaHeight(e);
                }}
                onBlur={formik.handleBlur}
                rows={1}
              ></textarea>
              {InputErrorMessage({
                props: formik,
                type: 'suggestedAnswerPrompt',
              })}
            </div>
            <div className="w-712 flex flex-col gap-10 mb-50">
              <Typography weight="bold">First message</Typography>
              <textarea
                className="w-full border border-light-grey-3 rounded-md focus:outline-none text-sm font-semibold px-20 py-10 resize-none overflow-hidden"
                name="firstMessage"
                value={formik.values.firstMessage}
                onChange={(e) => {
                  formik.handleChange(e);
                  adjustTextareaHeight(e);
                }}
                onBlur={formik.handleBlur}
                rows={1}
              ></textarea>
              {InputErrorMessage({
                props: formik,
                type: 'firstMessage',
              })}
            </div>
            <div>
              <Button
                type="main"
                id="SinglePrompt.UpdateButton"
                isLoading={PPending}
                buttonType="submit"
              >
                Update
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SinglePrompt;
