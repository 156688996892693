import * as Yup from 'yup';

const singlePromptSchema = Yup.object({
  unifiedPromptMessage: Yup.string().nullable(),
  beginnerPromptMessage: Yup.string().nullable(),
  intermediatePromptMessage: Yup.string().nullable(),
  advancedPromptMessage: Yup.string().nullable(),
  suggestedAnswerPrompt: Yup.string().nullable(),
  firstMessage: Yup.string().nullable(),
});

export default singlePromptSchema;
