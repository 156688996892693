import React, { ReactNode } from "react";

interface TypographyProps {
  children: ReactNode;
  color?: string;
  size?: string;
  weight?: string;
  className?: string;
}

const Typography: React.FC<TypographyProps> = ({
  children,
  color,
  size,
  weight,
  className,
}) => {
  const styles = `${weight ? "font-" + weight : "font-normal"}  ${color ? "text-" + color : "text-dark-grey"} ${size ? "text-" + size : "text-sm"} ${className} `;

  return <p className={styles}>{children}</p>;
};

export default Typography;
