import React, { SVGProps, useEffect, useState } from "react";
import StateManagedSelect, {
  ActionMeta,
  MultiValue,
  Props,
  SingleValue,
} from "react-select";
import Select from "react-select";

interface Option {
  value: string;
  label: string;
}

type DropdownProps = {
  options: Option[];
  onChange?: (selectedOption: Option | null) => void;
  SelectedValue?: Option;
  className?: string;
} & Omit<Props<Option>, "onChange">;

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  onChange,
  className,
  SelectedValue,
  ...init
}) => {
  const [selectedValue, setSelectedValue] = useState<Option | null | undefined>(
    SelectedValue
  );

  const handleSelectChange = (
    newValue: MultiValue<Option> | SingleValue<Option>
  ): void => {
    setSelectedValue(newValue as Option | null);
    if (onChange) {
      onChange(newValue as Option | null);
    }
  };

  const customStyles = {
    option: (provided: any, state: { isSelected: boolean }) => ({
      ...provided,
      borderBottom: "1px solid #D5D5D5",
      color: "#000",
      backgroundColor: "transparent",
      fontSize: "1.4rem",
    }),
    control: (provided: any, state: { isFocused: boolean }) => ({
      ...provided,
      height: "3rem",
      // padding: "2px",
      fontSize: "1.4rem",
      boxShadow: "none",
      borderWidth: "1px",
      color: "#000",
      minHeight: "none",
      "&:hover": {
        borderColor: "#D5D5D5",
      },
      "& input": {
        color: "#000",
      },
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: "none",
    }),
    indicatorContainer: (provided: any) => ({
      ...provided,
      padding: "0 !important",
      "& svg": {
        fill: "#fff",
        height: "24px",
        width: "24px",
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#8F8F8F",
      position: "absolute",
      zIndex: 2,
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 11,
    }),
    input: (provided: any) => ({
      ...provided,
      zIndex: 11,
    }),
    // dropdownIndicator: (provided: any) => ({
    //   ...provided,
    //   cursor: "pointer",
    //   svg: {
    //     width: "24px",
    //     height: "24px",
    //     "&hover": {
    //       color: "#fff",
    //     },
    //   },
    // }),
  };
  return (
    <Select
      options={options}
      value={selectedValue}
      styles={customStyles}
      className={`${className} custom-select`}
      {...init}
      onChange={handleSelectChange}
    />
  );
};
