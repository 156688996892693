import {useMutation, UseMutationOptions,} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import axiosInstance, {CustomAxiosError} from "../../../utils/axios";
import {MutationKeys} from "../../mutation/mutation.enum";
import {AxiosError, AxiosResponse} from "axios";
import {UserResponse} from "../../../models/Interface/User.interface";

interface IDeleteSubUSer {
    subUserId:string
}
type IDeleteSubUser = UseMutationOptions<AxiosResponse<UserResponse>, AxiosError, IDeleteSubUSer>



const useDeleteSubUser = (options?: IDeleteSubUser) => {
    const navigation = useNavigate()
    const mutation =  useMutation({
        ...options,
        mutationFn: (data: {
           subUserId:string
        }) => {
            return axiosInstance.delete(`/users/sub/${data.subUserId}`)
        },
        mutationKey:[MutationKeys.DELETE_SUB_USER],
        onSuccess: (res, res1, res2) => {
            options?.onSuccess?.(res, res1, res2)
        },
        onError: (error: CustomAxiosError, variables, context) => {
            alert(error?.response?.data?.message )
            options?.onError?.(error, variables, context)
        },
    })


    return {
        mutateDeleteUser:mutation.mutate,
        ...mutation,
    }
}
export default useDeleteSubUser