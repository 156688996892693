import Typography from '../../commons/Typography/Typography';
import '../../css/ScrollbarStyles.css';
import { useNavigate } from 'react-router';
import useSubUsers, { IFilters } from '../../state/hooks/users/useSubUsers';
import { Fragment, useEffect, useState } from 'react';
import { FiltersComponent } from '../../commons/FiltersComponent/FiltersComponent';
import FormatDate from '../../utils/helpers/formatDate';

const filterOptions = [
  { value: 'email', label: 'Email' },
  { value: 'firstName', label: 'First Name' },
  { value: 'lastName', label: 'Last Name' },
  { value: 'search', label: 'Search' },
  { value: 'subUserId', label: 'Sub user ID' },
  { value: 'id', label: 'Main user ID' },
  { value: 'accountType', label: 'Account Type' },
];

const SubUsers = () => {
  const navigate = useNavigate();
  const [filtersCount, setFiltersCount] = useState<IFilters>({});

  const {
    data,
    isLoading,
    isError,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useSubUsers(true, filtersCount);

  useEffect(() => {
    addFilter();
  }, []);

  const addFilter = () => {
    const newFilter = filterOptions.find(
      (option) => !Object.keys(filtersCount).includes(option.value)
    );
    if (newFilter) {
      setFiltersCount((prevFilters) => ({
        ...prevFilters,
        [newFilter.value]: '',
      }));
    }
  };

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;

    if (bottom) {
      if (isFetchingNextPage && hasNextPage) return;
      fetchNextPage();
    }
  };
  return (
    <div className="flex px-30 pt-55 pb-20 flex-col bg-light-grey-1 h-full w-full">
      <h2 className="text-dark-grey font-bold text-2xl mb-30">Sub users</h2>
      <FiltersComponent
        setFiltersCount={setFiltersCount}
        filterOptions={filterOptions} // Pass filterOptions as prop
        filtersCount={filtersCount}
      />
      <div
        className="overflow-x-auto rounded-xl border-light-grey-3 border-solid border"
        onScroll={handleScroll}
      >
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>{JSON.stringify(error)}</div>
        ) : (
          <table className="table-auto w-full overflow-x-auto bg-white rounded-xl border-collapse text-dark-grey">
            <thead className="sticky top-0">
              <tr className=" bg-gray-100 h-48 bg-light-grey-4">
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Email</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">First name</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Last name</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Package</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Account type</Typography>
                </th>

                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Creation Date</Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.pages.map((userList) => (
                <Fragment key={userList.meta.page}>
                  {userList?.data?.map((user) =>
                    user?.subUsers?.map((subUser, index) => (
                      <tr
                        className="cursor-pointer border-light-grey-3 border-solid border-t hover:bg-light-grey-1"
                        key={subUser.id}
                        onClick={() => {
                          {
                            const url = `/sub-users/${subUser.id}`;
                            window.open(url, '_blank');
                          }
                        }}
                      >
                        <td className="px-20 py-20">
                          <Typography weight="semibold">
                            {user.email}
                          </Typography>
                        </td>
                        <td className="px-20 py-20">
                          <Typography weight="semibold">
                            {subUser.firstName}
                          </Typography>
                        </td>
                        <td className="px-20 py-20">
                          <Typography weight="semibold">
                            {subUser.lastName}
                          </Typography>
                        </td>
                        <td className="px-20 py-20">
                          <Typography weight="semibold">
                            {subUser.package}
                          </Typography>
                        </td>
                        <td className="px-20 py-20">
                          <Typography weight="semibold">
                            {subUser.accountType}
                          </Typography>
                        </td>

                        <td className="px-20 py-20">
                          <Typography weight="semibold">
                            {FormatDate(subUser.createdAt) || ''}
                          </Typography>
                        </td>
                      </tr>
                    ))
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default SubUsers;
