import { useLocation } from 'react-router-dom';
import { Formik, FormikValues } from 'formik';
import Button from '../../commons/Button/Button';
import { FormField } from '../../commons/FormField/FormField';
import '../../css/ScrollbarStyles.css';
import useSubscription from '../../state/hooks/subscriptions/useSubscription';
import TextInput from '../../commons/TextInput/TextInput';
import Typography from '../../commons/Typography/Typography';
import useMe from '../../state/hooks/auth/useMe';
import useUpdateSubscription from '../../state/hooks/subscriptions/useUpdateSubscription';
import { DropdownWithSearch } from '../../commons/Dropdown';
import getChangedValues from '../../utils/helpers/getChangedValues';
import nextBillingDate from "../../utils/helpers/nextBillingDate";
import formatDate from "../../utils/helpers/formatDate";

const Subscription = () => {
  const query = useLocation();
  const subscriptionId = query.pathname.replace('/subscriptions/', '');
  const { data: meData, isLoading: mLoading } = useMe();
  const { mutate, isPending: UPending } = useUpdateSubscription({
    onSuccess: (data, variables, context) => {
      console.warn('subscription update success');
    },
  });

  const statusOptions = [
    { label: 'draft', value: 'draft' },
    { label: 'active', value: 'active' },
    { label: 'canceled', value: 'canceled' },
  ];

  const packageOptions = [
    { label: 'Free Subscription', value: 'Free Subscription' },
    { label: 'Monthly Subscription', value: 'Monthly Subscription' },
    { label: 'Quarterly Subscription', value: 'Quarterly Subscription' },
    { label: 'Yearly Subscription', value: 'Yearly Subscription' },
  ]

  const {
    data: SData,
    isLoading,
    isPending,
    isError,
    error,
    isSuccess,
    isFetching,
  } = useSubscription(subscriptionId);

  if (isLoading || isPending || mLoading || isFetching)
    return <div>Loading...</div>;

  if (isError && error)
    return (
      <div className="flex flex-1 justify-center items-center h-full">
        <Typography>{JSON.stringify(error.response?.data.message)}</Typography>
      </div>
    );

  const initialValues = {
    id: SData?.data?.id || '',
    createdAt: SData?.data?.createdAt || '',
    paymentGateway: SData?.data?.paymentGateway || '',
    package: SData?.data?.package || '',
    status: SData?.data?.status || '',
    amount: SData?.data?.amount || 0,
    country: SData?.data?.country || '',
    nextBillingDate: SData?.data?.nextBillingDate || '',
    subscriptionId: SData?.data?.subscriptionId || '',
    currency: SData?.data?.currency || '',
    appUserId: SData?.data?.appUserId || '',
    taxRate: SData?.data?.taxRate || 0,
    toBeCancelled: SData?.data?.toBeCancelled || false,
    planId: SData?.data?.planId || '',
    subUser: SData?.data?.subUser || {},
  };

  const handleSubmit = async (values: any) => {
    const changedValues = getChangedValues(initialValues, values);
    if (Object.keys(changedValues).length > 0) {
      mutate({ subscriptionId, data: changedValues });
    }
  };

  return (
    <div className="flex overflow-x-auto px-30 pt-80 pb-20 flex-col bg-light-grey-1 h-full">
      <h2 className="text-dark-grey font-bold text-2xl">Subscription</h2>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik: FormikValues) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-50 mb-30">
              <FormField label="ID">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="id"
                  defaultValue={formik.values.id}
                  name="id"
                />
              </FormField>
              <FormField label="Creation Date">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="createdAt"
                  defaultValue={formatDate(formik.values.createdAt) || ''}
                  name="createdAt"
                  disabled
                />
              </FormField>
              <FormField label="Payment Gateway">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="paymentGateway"
                  defaultValue={formik.values.paymentGateway}
                  name="paymentGateway"
                />
              </FormField>
              <FormField label="Package">
                <DropdownWithSearch
                  options={packageOptions ?? []}
                  className="w-360"
                  onChange={(e) => formik.setFieldValue('package', e?.value)}
                  id="Subscription.PackageSelect"
                  value={formik.values.package}
                />
              </FormField>
              <FormField label="Status">
                <DropdownWithSearch
                  options={statusOptions ?? []}
                  className="w-360"
                  onChange={(e) => formik.setFieldValue('status', e?.value)}
                  id="Subscription.StatusSelect"
                  value={formik.values.status}
                />
              </FormField>
              <FormField label="Amount">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="number"
                  onChange={formik.handleChange}
                  id="amount"
                  defaultValue={formik.values.amount}
                  name="amount"
                />
              </FormField>
              <FormField label="Country">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="country"
                  defaultValue={formik.values.country}
                  name="country"
                />
              </FormField>
              <FormField label="Next Billing Date">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="nextBillingDate"
                  defaultValue={nextBillingDate(formik.values.nextBillingDate)}
                  name="nextBillingDate"
                />
              </FormField>
              <FormField label="Subscription ID">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="subscriptionId"
                  defaultValue={formik.values.subscriptionId}
                  name="subscriptionId"
                />
              </FormField>
              <FormField label="Currency">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="currency"
                  defaultValue={formik.values.currency}
                  name="currency"
                />
              </FormField>
              <FormField label="App User ID">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="appUserId"
                  defaultValue={formik.values.appUserId}
                  name="appUserId"
                />
              </FormField>
              <FormField label="Tax Rate">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="number"
                  onChange={formik.handleChange}
                  id="taxRate"
                  defaultValue={formik.values.taxRate}
                  name="taxRate"
                />
              </FormField>
              <FormField label="To Be Cancelled">
                <input
                  type="checkbox"
                  checked={formik.values.toBeCancelled}
                  onChange={formik.handleChange}
                  name="toBeCancelled"
                  id="Subscription.ToBeCancelled"
                />
              </FormField>
              <FormField label="Plan ID">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="planId"
                  defaultValue={formik.values.planId}
                  name="planId"
                />
              </FormField>
              <FormField label="Sub User ID">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="subUser"
                  defaultValue={formik.values.subUser.id}
                  name="subUser"
                />
              </FormField>
              <FormField label="User Email">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="userEmail"
                  defaultValue={formik.values.subUser.user.email}
                  name="subUser"
                />
              </FormField>
            </div>
            <div>
              <Button
                type="main"
                id="Subscription.UpdateButton"
                onClick={() => handleSubmit(formik.values)}
                isLoading={UPending}
              >
                Update
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Subscription;
