import axiosInstance from '../../../utils/axios';
import { IErrorResponse, QueryKeys } from '../../querys/querys.enum';
import { AxiosError } from 'axios';
import { ISubUserResponse } from '../../../models/Interface/User.interface';
import { useAppQuery } from '../../querys/useAppQuery';
import { B2BAdminResponse } from '../../../models/Interface/B2BAdmin.interface';

const useB2BAdmin = (b2bAdminId: string) => {
  return useAppQuery<B2BAdminResponse, AxiosError<IErrorResponse>>({
    queryKey: [
      QueryKeys.B2B_ADMIN,
      {
        b2bAdminId,
      },
    ],
    //TODO: replace url path with correct one
    queryFn: async () => {
      return (await axiosInstance.get(`/b2b-admins/${b2bAdminId}`)).data;
    },
    enabled: !!b2bAdminId,
    retry: 0,
  });
};
export default useB2BAdmin;
