import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  IPromptCreateResponse,
  Prompt,
} from '../../../models/Interface/Prompt.interface';
import axiosInstance, { CustomAxiosError } from '../../../utils/axios';
import { AxiosError } from 'axios';
import { IErrorResponse } from '../../querys/querys.enum';
import { MutationKeys } from '../../mutation/mutation.enum';

const useCreatePromptReq = async (data: Prompt) => {
  const response = await axiosInstance.post('/prompts/create', data);
  return response.data;
};

const useCreatePrompt = (
  options?: Partial<
    UseMutationOptions<
      IPromptCreateResponse,
      AxiosError<IErrorResponse>,
      Prompt
    >
  >
) => {
  return useMutation<IPromptCreateResponse, AxiosError<IErrorResponse>, Prompt>(
    {
      mutationKey: [MutationKeys.CREATE_PROMPT],
      mutationFn: async (data) => {
        return await useCreatePromptReq({ ...data });
      },
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);

        alert(data.message);
      },
      onError: (error: CustomAxiosError, variables, context) => {
        alert(error?.response?.data?.message);
        options?.onError?.(error, variables, context);
      },
    }
  );
};

export default useCreatePrompt;
