import { useFormik } from "formik";
import Button from "../../commons/Button/Button";
import { Dropdown } from "../../commons/Dropdown/Dropdown";
import { FormField } from "../../commons/FormField/FormField";
import InputField from "../../commons/InputField/InputField";
import "../../css/ScrollbarStyles.css";
import TextInput from "../../commons/TextInput/TextInput";

const users = [
    { value: 'Doe', label: '1' },
    { value: 'Smith', label: '2' },
    { value: 'Johnson', label: '3' },
];

const NewSubscription = () => {
    const formik = useFormik({
        initialValues: {
            uuid: "",
            paymentGetaway: "",
            subscriptionId: "",
            package: "",
            status: "",
            user: "",
            planID: "",
            amount: 0,
            currency: "",
            country: "",
            taxRate: 0,
            nextBillingDate: "",
            toBeCancelled: ""
        },
        onSubmit: () => { },
    });
    return (
        <div className="flex overflow-x-auto px-30 pt-80 pb-20 flex-col bg-light-grey-1 h-full">
            <h2 className="text-dark-grey font-bold text-2xl">Add New Subscription</h2>
            <div className="mt-50 mb-30">
                <form onSubmit={(e) => e.preventDefault()}>
                    <FormField label="UUID">
                        <TextInput
                            customStyles="flex-col w-360"
                            placeholder={""}
                            customInputStyles="h-12 w-full"
                            type="text"
                            onChange={(e) => {
                                formik.handleChange("uuid")(e.target.value);
                            }}
                            id="NewSubscription.UuidField"
                            value={formik.values.uuid}
                        />
                    </FormField>
                    <FormField label="Payment getaway">
                        <Dropdown options={users} className="w-360" id="NewSubscription.PaymentGetawaySelect"/>
                    </FormField>
                    <FormField label="Subscription ID">
                        <TextInput
                            customStyles="flex-col w-360"
                            placeholder={""}
                            customInputStyles="h-12 w-full"
                            type="text"
                            onChange={(e) => {
                                formik.handleChange("subscriptionId")(e.target.value);
                            }}
                            id="NewSubscription.SubscriptionIdField"
                            value={formik.values.subscriptionId}
                        />
                    </FormField>
                    <FormField label="Package">
                        <Dropdown options={users} className="w-360" id="NewSubscription.PackageSelect"/>
                    </FormField>
                    <FormField label="Status">
                        <Dropdown options={users} className="w-360" id="NewSubscription.StatusSelect"/>
                    </FormField>
                    <FormField label="User">
                        <Dropdown options={users} className="w-360" id="NewSubscription.UserSelect"/>
                    </FormField>
                    <FormField label="Plan ID">
                        <TextInput
                            customStyles="flex-col w-360"
                            placeholder={""}
                            customInputStyles="h-12 w-full"
                            type="text"
                            onChange={(e) => {
                                formik.handleChange("planID")(e.target.value);
                            }}
                            id="NewSubscription.PlanIdField"
                            value={formik.values.planID}
                        />
                    </FormField>
                    <FormField label="Amount">
                        <TextInput
                            customStyles="flex-col w-360"
                            placeholder={""}
                            customInputStyles="h-12 w-full"
                            type="number"
                            onChange={(e) => {
                                formik.handleChange("amount")(e.target.value);
                            }}
                            id="NewSubscription.AmountField"
                            value={formik.values.amount}
                        />
                    </FormField>
                    <FormField label="Currency">
                        <TextInput
                            customStyles="flex-col w-360"
                            placeholder={""}
                            customInputStyles="h-12 w-full"
                            type="text"
                            onChange={(e) => {
                                formik.handleChange("currency")(e.target.value);
                            }}
                            id="NewSubscription.CurrencyField"
                            value={formik.values.currency}
                        />
                    </FormField>
                    <FormField label="Country">
                        <TextInput
                            customStyles="flex-col w-360"
                            placeholder={""}
                            customInputStyles="h-12 w-full"
                            type="text"
                            onChange={(e) => {
                                formik.handleChange("country")(e.target.value);
                            }}
                            id="NewSubscription.CountryField"
                            value={formik.values.country}
                        />
                    </FormField>
                    <FormField label="Tax rate">
                        <TextInput
                            customStyles="flex-col w-360"
                            placeholder={""}
                            customInputStyles="h-12 w-full"
                            type="number"
                            onChange={(e) => {
                                formik.handleChange("taxRate")(e.target.value);
                            }}
                            id="NewSubscription.TaxRateField"
                            value={formik.values.taxRate}
                        />
                    </FormField>
                    <FormField label="Next billing date">
                        <TextInput
                            customStyles="flex-col w-360"
                            placeholder={""}
                            customInputStyles="h-12 w-full"
                            type="number"
                            onChange={(e) => {
                                formik.handleChange("taxRate")(e.target.value);
                            }}
                            id="NewSubscription.NextBillingDateField"
                            value={formik.values.taxRate}
                        />
                    </FormField>
                    <FormField label="To be cancelled">
                        <Dropdown options={users} className="w-360" id="NewSubscription.ToBeCancelledSelect"/>
                    </FormField>
                </form>
            </div>
            <div>
                <Button type="main">Add</Button>
            </div>
        </div>
    );
};

export default NewSubscription;
