export const FormField = ({
  style,
  label,
  children,
  col = false,
}: {
  style?: string;
  label: string;
  children: React.ReactNode;
  col?: boolean;
}) => (
  <div className={`flex items-center mb-30 gap-50 flex-${col ? "col" : "row"}`}>
    <h3
      className={`text-dark-grey font-bold ${style ? style : "text-sm"} w-1/4`}
    >
      {label}
    </h3>
    <div className="flex flex-col w-3/4 items-start">{children}</div>
  </div>
);
