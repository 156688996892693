import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import axiosInstance from '../../../utils/axios';
import { defaultQueryOptions, QueryKeys } from '../../querys/querys.enum';
import { B2BAdminsResponse } from '../../../models/Interface/B2BAdmin.interface';

export interface IFileterInterface {
  value: string;
  label: string;
  search?: string;
}
export interface IFilters {
  email?: string;
  firstName?: string;
  lastName?: string;
  search?: string;
  adminId?: string;
  active?: boolean;
  organizationName?: string;
  subscriptionStatus?: string;
}

const useB2BAdmins = (isEnabled: boolean, params: IFilters) => {
  return useInfiniteQuery<B2BAdminsResponse>({
    queryKey: [QueryKeys.B2B_ADMINS, params],
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1, queryKey }) => {
      const d = queryKey[1] as IFilters;

      const email = d.email ? `&email=${encodeURIComponent(d.email)}` : '';
      const firstName = d.firstName
        ? `&firstName=${encodeURIComponent(d.firstName)}`
        : '';
      const lastName = d.lastName
        ? `&lastName=${encodeURIComponent(d.lastName)}`
        : '';
      const search = d.search ? `&search=${encodeURIComponent(d.search)}` : '';
      const adminId = d.adminId
        ? `&adminId=${encodeURIComponent(d.adminId)}`
        : '';
      const active = d.active ? `&active=${encodeURIComponent(d.active)}` : '';
      const organizationName = d.organizationName
        ? `&organizationName=${encodeURIComponent(d.organizationName)}`
        : '';
      const subscriptionStatus = d.subscriptionStatus
        ? `&subscriptionStatus=${encodeURIComponent(d.subscriptionStatus)}`
        : '';

      return (
        await axiosInstance.get(
          `/b2b-admins?page=${pageParam}${email}${firstName}${lastName}${search}${adminId}${active}${organizationName}${subscriptionStatus}`
        )
      ).data;
    },
    getNextPageParam: (lastPage, allPages, lastPageParam, allParams) => {
      if (lastPage.meta.totalPage === lastPage.meta.page) return undefined;
      return lastPage.meta.page + 1;
    },
    ...defaultQueryOptions,
    refetchOnWindowFocus: true,
    initialData: undefined,
    enabled: isEnabled,
    placeholderData: keepPreviousData,
  });
};
export default useB2BAdmins;
