import axiosInstance from "../../../utils/axios"
import { QueryKeys} from "../../querys/querys.enum"
import {useAppQuery} from "../../querys/useAppQuery"
import {getItem, LOCAL_STORAGE} from "../../../utils/settings/storage.settings";


const useMe = () => {
    return useAppQuery({
        queryKey: [QueryKeys.USER],
        queryFn: async () => {

            if (!getItem('token', LOCAL_STORAGE)) {
                return Promise.reject({message: 'No token found', response: {status: 401}})
            }
            return (await axiosInstance.get('/auth/me')).data
        },
        throwOnError:true,
        retry: 0,
        refetchInterval: 1000 * 60 * 5,
        _defaulted: true,
        gcTime: 1000 * 60 * 60 * 1,
        refetchOnWindowFocus: false,
    })

}
export default useMe