import {useMutation, UseMutationOptions, useQueryClient} from "@tanstack/react-query"
import axiosInstance, {CustomAxiosError} from "../../../utils/axios"
import {QueryKeys} from "../../querys/querys.enum"
import {useNavigate} from "react-router-dom"
import {AxiosError, AxiosResponse} from "axios"
import {UserResponse} from "../../../models/Interface/User.interface"
import {LOCAL_STORAGE, setItem} from "../../../utils/settings/storage.settings";
import {MutationKeys} from "../../mutation/mutation.enum";


interface ILoginCredentials {
    email: string
    password: string
    remember: boolean
}
type IUseLogin = UseMutationOptions<AxiosResponse<UserResponse>, AxiosError, ILoginCredentials>



const useLogin = (options?: IUseLogin) => {
    const queryClient = useQueryClient()
    const navigation = useNavigate()
    return useMutation({
        ...options,
        mutationFn: (data: {
            email: string,
            password: string
            remember: boolean
        }) => {
            return axiosInstance.post('/auth/admin/login', data)
        },
        mutationKey:[MutationKeys.LOGIN],
        onSuccess: (res, res1, res2) => {
            queryClient.setQueryData([QueryKeys.USER], res.data);
            setItem('token', res.data.token.accessToken,LOCAL_STORAGE);
            setItem('refreshToken', res.data.token.refreshToken,LOCAL_STORAGE);
            options?.onSuccess?.(res, res1, res2)
            navigation("/sub-users");

        },
        onError: (error: CustomAxiosError, variables, context) => {
            alert(error?.response?.data?.message )
            options?.onError?.(error, variables, context)
        },
    })
}
export default useLogin