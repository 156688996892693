export const languagesList = [
    "de",
    "fr",
    "es-es",
    "es-mx",
    "af",
    "fi",
    "en-gb",
    "hi",
    "it",
    "ja",
    "ko",
    "nl",
    "pt-br",
    "pt-pt",
    "sv",
    "zh-cn",
    "zh-hk",
    "zh-tw",
    "uk",
    "bn",
    "bg",
    "hr",
    "cs",
    "da",
    "et",
    "tl",
    "ka",
    "el",
    "hu",
    "id",
    "lv",
    "lt",
    "ms",
    "no",
    "pl",
    "ro",
    "sk",
    "sl",
    "th",
    "tr",
    "uz",
    "vi",
];