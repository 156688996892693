import * as Yup from "yup";
import Package from "../enum/package.enum";
import Source from "../enum/source.enum";

const subUserSchema = Yup.object().shape({
    firstName: Yup.string().default('').nullable(),
    lastName: Yup.string().default('').nullable(),
    createdAt: Yup.string().default('').nullable(),
    email: Yup.string().email('Invalid email format').default('').nullable(),
    source: Yup.mixed().oneOf(Source).default('web').nullable(),
    package: Yup.string().oneOf(Package).default('').nullable(),
    targetLanguage: Yup.string().default('').nullable(),
    currentLevel: Yup.string().default('').nullable(),
    primaryGoal: Yup.string().default('').nullable(),
    nativeLanguage: Yup.string().default('').nullable(),
    practiceFrequency: Yup.string().default('').nullable(), // Adjust this according to mapLabelToKey return type
    mute: Yup.boolean().default(false),
    sentenceModeMute: Yup.boolean().default(false),
    hadPremium: Yup.boolean().default(false),
    interfaceLanguage: Yup.string().default('').nullable(),
    isSuggest: Yup.boolean().default(false),
    isHandsFree: Yup.boolean().default(false),
    isHandsFreeCallMode: Yup.boolean().default(false),
    isHandsFreeSentenceMode: Yup.boolean().default(false),
    isDarkMode: Yup.boolean().default(false),
    chatName: Yup.string().default('').nullable(),
    speechSpeed: Yup.number().default(1),
    newsletter: Yup.boolean().default(false),
    timeZone: Yup.string().default('UTC+00:00').nullable(),
    handsFreeInterval: Yup.number().default(3),
    handsFreeIntervalCallMode: Yup.number().default(3),
    handsFreeIntervalSentenceMode: Yup.number().default(3),
    fontSize: Yup.number().default(0),
    abTesting: Yup.object().shape({
        abTestingVersion: Yup.string().default('').nullable(),
        abTestingVersionSlot2: Yup.string().default('').nullable(),
        abTestingVersionSlot3: Yup.string().default('').nullable(),
        abTestingVersionSlot4: Yup.string().default('').nullable(),
        abTestingVersionSlot5: Yup.string().default('').nullable(),
    }).nullable(),
});

export default subUserSchema;

