import React, {Children, ReactNode} from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

interface NavbarLinkProps {
  children: ReactNode;
  linkTo: string;
}
  
const NavbarLink: React.FC<NavbarLinkProps> = ({ children, linkTo }) => {
  const { pathname } = useLocation();
  
  const activeStyles = 'bg-light-grey-1 text-primary shadow-md';

  const styles = `h-50 cursor-pointer font-bold w-full flex pl-8 content-center text-base flex-wrap ${pathname === linkTo ? activeStyles : "" }`

  return <Link to={linkTo} className={styles}>
    {children}
  </Link>;
}

export default NavbarLink;
