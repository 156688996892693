import { useLocation } from "react-router-dom";
import { Formik, FormikValues } from "formik";
import Button from "../../commons/Button/Button";
import { FormField } from "../../commons/FormField/FormField";
import "../../css/ScrollbarStyles.css";
import useSubUser from "../../state/hooks/users/useSubUser";
import TextInput from "../../commons/TextInput/TextInput";
import Typography from "../../commons/Typography/Typography";
import useMe from "../../state/hooks/auth/useMe";
import { DropdownWithSearch } from "../../commons/Dropdown";
import useLanguage from "../../state/hooks/languages/hook/useLanguage";
import { useMemo } from "react";
import useAppParams from "../../state/hooks/app/useAppParams";
import useUpdateSubUser from "../../state/hooks/users/useUpdateSubUser";
import timezones from "timezones-list";
import subUserSchema from "../../models/schemas/sub-user.schema";
import SubUserInitialValues from "../../models/initial-values/subUser-initial-values";
import { InputErrorMessage } from "../../commons/error";
import useDeleteSubUser from "../../state/hooks/users/useDeleteSubUser";
import getChangedValues from "../../utils/helpers/getChangedValues";
import FormatDate from "../../utils/helpers/formatDate";

const SubProfile = () => {
  const query = useLocation();
  const userId = query.pathname.replace("/sub-users/", "");
  const { data: meData, isLoading: mLoading } = useMe();
  const { data, isLoading: LLoading } = useLanguage();
  const { data: AppData } = useAppParams();

  const { mutateDeleteUser, isPending: UDeleteSubUser } = useDeleteSubUser({
    onSuccess: () => {
      alert("Deleted SubUser success");
      refetch();
    },
    onError: (error) => {
      alert(`Failed to delete SubUser: ${error}`);
    },
  });

  const handleDeleteSubUser = () => {
    const question = confirm("Are you sure you want to delete this user?");

    if (question) {
      mutateDeleteUser({ subUserId: userId });
    }
  };

  const { mutate, isPending: UPending } = useUpdateSubUser({
    onSuccess: (data, variables, context) => {
      console.warn("sub user update success");
      refetch();
    },
  });

  const sortedActiveLangs = data?.languages
    ?.slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  const ModifiedNativeLang = useMemo(
    () =>
      sortedActiveLangs?.map((lang: { name: string; slug: string }) => ({
        value: lang.slug,
        label: lang.name,
      })),
    [sortedActiveLangs]
  );

  const ModifiedTargetLang = useMemo(() => {
    const filterTarget = sortedActiveLangs?.filter(
      (lang: { name: string; slug: string; target: boolean }) => lang.target
    );
    return filterTarget?.map(
      (lang: { name: string; slug: string; target: boolean }) => ({
        value: lang.slug,
        label: lang.name,
      })
    );
  }, [sortedActiveLangs]);

  const memoizeField = (fieldData: { [key: string]: string } | undefined) => {
    if (!fieldData) return [];
    return Object.keys(fieldData).map((key) => ({
      value: key,
      label: fieldData[key],
    }));
  };

  const ModifiedSourceData = useMemo(() => {
    const data = memoizeField(AppData?.data?.source);
    return data;
  }, [AppData?.data?.source]);

  const ModifiedTimeZoneData = useMemo(() => {
    const uniqueTimezones = new Map();

    timezones.forEach((tz) => {
      const SplitValidData = `UTC${tz.utc}`;
      if (!uniqueTimezones.has(SplitValidData)) {
        uniqueTimezones.set(SplitValidData, {
          value: SplitValidData,
          label: SplitValidData,
        });
      }
    });

    return Array.from(uniqueTimezones.values());
  }, []);

  const ModifiedLevelsData = useMemo(() => {
    const data = memoizeField(AppData?.data?.levels);
    return data;
  }, [AppData?.data?.levels]);

  const ModifiedGoalsData = useMemo(() => {
    const data = memoizeField(AppData?.data?.goals);
    return data;
  }, [AppData?.data?.goals]);

  const ModifiedFrequenciesData = useMemo(() => {
    const data = memoizeField(AppData?.data?.frequencies);
    return data;
  }, [AppData?.data?.frequencies]);

  const packageOptions = [
    { label: "Free Subscription", value: "Free Subscription" },
    { label: "Monthly Subscription", value: "Monthly Subscription" },
    { label: "Quarterly Subscription", value: "Quarterly Subscription" },
    { label: "Yearly Subscription", value: "Yearly Subscription" },
  ];

  const {
    refetch,
    data: UData,
    isLoading,
    isPending,
    isError,
    error,
    isSuccess,
    isFetching,
  } = useSubUser(meData?.id === userId ? undefined : userId);

  if (isLoading || isPending || mLoading || isFetching || LLoading)
    return <div>Loading...</div>;

  if (isError && error)
    return (
      <div className="flex flex-1 justify-center items-center h-full">
        <Typography>{JSON.stringify(error.response?.data.message)}</Typography>
      </div>
    );

  const initialValues = SubUserInitialValues({
    UData,
    AppData: AppData!,
  });

  const handleSubmit = async (values: any) => {
    const changedValues = getChangedValues(initialValues, values);


    if (Object.keys(changedValues).length > 0) {
      mutate({ userId, data: changedValues });
    }
  };

  return (
    <div className="flex overflow-x-auto px-30 pt-80 pb-20 flex-col bg-light-grey-1 h-full">
      <h2 className="text-dark-grey font-bold text-2xl">Profile</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={subUserSchema}
      >
        {(formik: FormikValues) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-50 mb-30">
              <FormField label="First name">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("firstName", e?.target.value)
                  }
                  id="Profile.FirstNameField"
                  value={formik.values.firstName}
                  name="firstName"
                />
              </FormField>
              <FormField label="Last name">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("lastName", e?.target.value)
                  }
                  id="Profile.LastNameField"
                  value={formik.values.lastName}
                  name="lastName"
                />
              </FormField>
              <FormField label="Creation Date">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("createdAt", e?.target.value)
                  }
                  id="Profile.CreationDate"
                  value={FormatDate(formik.values.createdAt) || ""}
                  name="createdAt"
                  disabled
                />
              </FormField>
              <FormField label="Email (required)">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={formik.handleChange}
                  id="Profile.EmailField"
                  value={formik.values.email}
                  name="email"
                />
                {InputErrorMessage({ props: formik, type: "email" })}
              </FormField>
              <FormField label="Package">
                <DropdownWithSearch
                  options={packageOptions ?? []}
                  className="w-360"
                  onChange={(e) => formik.setFieldValue("package", e?.value)}
                  id="Subscription.PackageSelect"
                  value={formik.values.package}
                />
              </FormField>
              <FormField label="Source">
                <DropdownWithSearch
                  options={ModifiedSourceData ?? []}
                  onChange={(e) => formik.setFieldValue("source", e?.value)}
                  className="w-360"
                  id="Profile.SourceSelect"
                  value={formik.values.source}
                />
              </FormField>
              <FormField label="Target language">
                <DropdownWithSearch
                  options={ModifiedTargetLang ?? []}
                  className="w-360"
                  onChange={(e) =>
                    formik.setFieldValue("targetLanguage", e?.value)
                  }
                  id="Profile.TargetLanguageSelect"
                  value={formik.values.targetLanguage}
                />
              </FormField>
              <FormField label="Current level">
                <DropdownWithSearch
                  options={ModifiedLevelsData ?? []}
                  className="w-360"
                  onChange={(e) =>
                    formik.setFieldValue("currentLevel", e?.value)
                  }
                  id="Profile.CurrentLevelSelect"
                  value={formik.values.currentLevel}
                />
              </FormField>
              <FormField label="Primary goal">
                <DropdownWithSearch
                  options={ModifiedGoalsData ?? []}
                  className="w-360"
                  onChange={(e) =>
                    formik.setFieldValue("primaryGoal", e?.value)
                  }
                  id="Profile.PrimaryGoalSelect"
                  value={formik.values.primaryGoal}
                />
              </FormField>
              <FormField label="Native language">
                <DropdownWithSearch
                  options={ModifiedNativeLang ?? []}
                  className="w-360"
                  onChange={(e) =>
                    formik.setFieldValue("nativeLanguage", e?.value)
                  }
                  id="Profile.NativeLanguageSelect"
                  value={formik.values.nativeLanguage}
                />
              </FormField>
              <FormField label="Practice frequency">
                <DropdownWithSearch
                  options={ModifiedFrequenciesData ?? []}
                  className="w-360"
                  onChange={(e) =>
                    formik.setFieldValue("practiceFrequency", e?.value)
                  }
                  id="Profile.PracticeFrequencySelect"
                  value={formik.values.practiceFrequency}
                />
              </FormField>
              <FormField label="Interface language">
                <DropdownWithSearch
                  options={ModifiedNativeLang ?? []}
                  className="w-360"
                  onChange={(e) =>
                    formik.setFieldValue("interfaceLanguage", e?.value)
                  }
                  id="Profile.InterfaceLanguageSelect"
                  value={formik.values.interfaceLanguage}
                />
              </FormField>
              <FormField label="Time Zone">
                <DropdownWithSearch
                  options={ModifiedTimeZoneData ?? []}
                  className="w-360"
                  onChange={(e) => formik.setFieldValue("timeZone", e?.value)}
                  id="Profile.TimeZoneSelect"
                  value={formik.values.timeZone}
                />
              </FormField>
              <div className="grid grid-cols-2 gap-x-2 gap-y-4">
                <FormField label="Mute">
                  <input
                    type="checkbox"
                    checked={formik.values.mute}
                    onChange={formik.handleChange}
                    name="mute"
                    id="Profile.MuteCheckbox"
                  />
                </FormField>
                <FormField label="Sentence mode mute">
                  <input
                    type="checkbox"
                    checked={formik.values.sentenceModeMute}
                    onChange={formik.handleChange}
                    name="sentenceModeMute"
                    id="Profile.SentenceModeMuteCheckbox"
                  />
                </FormField>
                <FormField label="Had Premium">
                  <input
                    type="checkbox"
                    checked={formik.values.hadPremium}
                    onChange={formik.handleChange}
                    name="hadPremium"
                    id="Profile.HadPremiumCheckbox"
                  />
                </FormField>
                <FormField label="Premium">
                  <input
                    type="checkbox"
                    checked={formik.values.premium}
                    onChange={formik.handleChange}
                    name="premium"
                    id="Profile.PremiumCheckbox"
                  />
                </FormField>
                <FormField label="Is Suggest">
                  <input
                    type="checkbox"
                    checked={formik.values.isSuggest}
                    onChange={formik.handleChange}
                    name="isSuggest"
                    id="Profile.IsSuggestCheckbox"
                  />
                </FormField>
                <FormField label="Is Hands Free">
                  <input
                    type="checkbox"
                    checked={formik.values.isHandsFree}
                    onChange={formik.handleChange}
                    name="isHandsFree"
                    id="Profile.IsHandsFreeCheckbox"
                  />
                </FormField>
                <FormField label="Is Hands Free Call Mode">
                  <input
                    type="checkbox"
                    checked={formik.values.isHandsFreeCallMode}
                    onChange={formik.handleChange}
                    name="isHandsFreeCallMode"
                    id="Profile.IsHandsFreeCallModeCheckbox"
                  />
                </FormField>
                <FormField label="Is Hands Free Sentence Mode">
                  <input
                    type="checkbox"
                    checked={formik.values.isHandsFreeSentenceMode}
                    onChange={formik.handleChange}
                    name="isHandsFreeSentenceMode"
                    id="Profile.IsHandsFreeSentenceModeCheckbox"
                  />
                </FormField>
                <FormField label="Test Mode For User">
                  <input
                    type="checkbox"
                    checked={formik.values.isTestUser}
                    onChange={(e) =>
                      formik.setFieldValue("isTestUser", e.target.checked)
                    }
                    name="isTestUser"
                    id="Profile.isTestUser"
                  />
                </FormField>
                <FormField label="Is Dark Mode">
                  <input
                    type="checkbox"
                    checked={formik.values.isDarkMode}
                    onChange={formik.handleChange}
                    name="isDarkMode"
                    id="Profile.IsDarkModeCheckbox"
                  />
                </FormField>
                <FormField label="Newsletter">
                  <input
                    type="checkbox"
                    checked={formik.values.newsletter}
                    onChange={formik.handleChange}
                    name="newsletter"
                    id="Profile.NewsletterCheckbox"
                  />
                </FormField>
              </div>
              <FormField label="Chat Name">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("chatName", e?.target?.value)
                  }
                  id="Profile.ChatNameField"
                  value={formik.values.chatName}
                  name="chatName"
                />
              </FormField>
              <FormField label="Speech Speed">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="number"
                  min="0.5"
                  max="1.5"
                  step="0.25"
                  onChange={(e) =>
                    formik.setFieldValue("speechSpeed", e?.target?.value)
                  }
                  id="Profile.SpeechSpeedField"
                  value={formik.values.speechSpeed}
                  name="speechSpeed"
                />
              </FormField>
              <FormField label="AB Testing Version">
                <TextInput
                    customStyles="flex-col w-360"
                    placeholder=""
                    customInputStyles="h-12 w-full"
                    type="text"
                    onChange={(e) =>
                        formik.setFieldValue("abTesting.abTestingVersion", e?.target?.value)
                    }
                    id="Profile.ABTestingVersionField"
                    value={formik.values?.abTesting?.abTestingVersion}
                    name="abTesting.abTestingVersion"
                />
              </FormField>

              <FormField label="AB Testing Version Slot 2">
                <TextInput
                    customStyles="flex-col w-360"
                    placeholder=""
                    customInputStyles="h-12 w-full"
                    type="text"
                    onChange={(e) =>
                        formik.setFieldValue("abTesting.abTestingVersionSlot2", e?.target?.value)
                    }
                    id="Profile.ABTestingVersionSlot2Field"
                    value={formik.values?.abTesting?.abTestingVersionSlot2}
                    name="abTesting.abTestingVersionSlot2"
                />
              </FormField>

              <FormField label="AB Testing Version Slot 3">
                <TextInput
                    customStyles="flex-col w-360"
                    placeholder=""
                    customInputStyles="h-12 w-full"
                    type="text"
                    onChange={(e) =>
                        formik.setFieldValue("abTesting.abTestingVersionSlot3", e?.target?.value)
                    }
                    id="Profile.ABTestingVersionSlot3Field"
                    value={formik.values?.abTesting?.abTestingVersionSlot3}
                    name="abTesting.abTestingVersionSlot3"
                />
              </FormField>

              <FormField label="AB Testing Version Slot 4">
                <TextInput
                    customStyles="flex-col w-360"
                    placeholder=""
                    customInputStyles="h-12 w-full"
                    type="text"
                    onChange={(e) =>
                        formik.setFieldValue("abTesting.abTestingVersionSlot4", e?.target?.value)
                    }
                    id="Profile.ABTestingVersionSlot4Field"
                    value={formik.values?.abTesting?.abTestingVersionSlot4}
                    name="abTesting.abTestingVersionSlot4"
                />
              </FormField>

              <FormField label="AB Testing Version Slot 5">
                <TextInput
                    customStyles="flex-col w-360"
                    placeholder=""
                    customInputStyles="h-12 w-full"
                    type="text"
                    onChange={(e) =>
                        formik.setFieldValue("abTesting.abTestingVersionSlot5", e?.target?.value)
                    }
                    id="Profile.ABTestingVersionSlot5Field"
                    value={formik.values?.abTesting?.abTestingVersionSlot5}
                    name="abTesting.abTestingVersionSlot5"
                />
              </FormField>
              <FormField label="Hands Free Interval">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="number"
                  min="0"
                  max="4"
                  onChange={(e) =>
                    formik.setFieldValue("handsFreeInterval", +e?.target?.value)
                  }
                  id="Profile.HandsFreeIntervalField"
                  value={formik.values.handsFreeInterval}
                  name="handsFreeInterval"
                />
              </FormField>
              <FormField label="Hands Free Interval Call Mode">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="number"
                  min="0"
                  max="4"
                  onChange={(e) =>
                    formik.setFieldValue(
                      "handsFreeIntervalCallMode",
                      +e?.target?.value
                    )
                  }
                  id="Profile.HandsFreeIntervalField"
                  value={formik.values.handsFreeIntervalCallMode}
                  name="handsFreeIntervalCallMode"
                />
              </FormField>
              <FormField label="Hands Free Interval Sentence Mode">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="number"
                  min="0"
                  max="4"
                  onChange={(e) =>
                    formik.setFieldValue(
                      "handsFreeIntervalSentenceMode",
                      +e?.target?.value
                    )
                  }
                  id="Profile.HandsFreeIntervalField"
                  value={formik.values.handsFreeIntervalSentenceMode}
                  name="handsFreeIntervalSentenceMode"
                />
              </FormField>
              <FormField label="Font Size">
                <TextInput
                  customStyles="flex-col w-360"
                  placeholder=""
                  customInputStyles="h-12 w-full"
                  type="number"
                  step="0.1"
                  min="0"
                  onChange={(e) =>
                    formik.setFieldValue("fontSize", e?.target?.value)
                  }
                  id="Profile.FontSizeField"
                  value={formik.values.fontSize}
                  name="fontSize"
                />
              </FormField>
              <FormField label="Delete user">
                <Button
                  type="secondary"
                  id="Profile.DeleteUserButton"
                  isLoading={UDeleteSubUser}
                  onClick={handleDeleteSubUser}
                  disabled={formik.values.email
                    .toLowerCase()
                    .includes("anonymous")}
                >
                  Delete
                </Button>
              </FormField>
            </div>
            <div>
              <Button
                type="main"
                id="Profile.UpdateButton"
                isLoading={UPending}
                buttonType="submit"
              >
                Update
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SubProfile;
