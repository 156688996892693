
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../commons/Button/Button";
import TextInput from "../../commons/TextInput/TextInput";
import { useFormik } from "formik";
import useLogin from "../../state/hooks/auth/useLogin";
import * as Yup from "yup";
import Typography from "../../commons/Typography/Typography";

const Login = () => {
  const { mutate, isPending } = useLogin({
    onSuccess: () => {
      console.warn("Login success");
      console.warn("Login success");
      console.warn("Login success");
    },
  });
  const navigation = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    onSubmit: (values) => {
      if (values.email === "" || values.password === "") {
        return alert("Please fill all the fields");
      }
      mutate({
        email: values.email,
        password: values.password,
        remember: values.rememberMe,
      });
    },
    validationSchema: Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().required(),
        }),
  });

  useEffect(() => {
    const user = window.localStorage.getItem("token");
    if (user) {
      navigation("/sub-users");
    }
  }, []);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-200 ">
      <div className=" lg:w-1/3 w-auto bg-white shadow-md items-center flex flex-col p-50 rounded-lg">
        <Typography color="black" size="lg" weight="bold" className="mb-32">
          Login
        </Typography>
        <form onSubmit={(e) => e.preventDefault()}>
          <TextInput
              label={"Email address:"}
              customStyles="flex-col mb-14 w-full"
              placeholder={"esteban_schiller@gmail.com"}
              labelStyles="self-start font-medium"
              customInputStyles="h-12 w-full"
              type="email"
              name={"email"}
              onChange={(e) => {
                formik.handleChange("email")(e.target.value);
              }}
              id="Login.EmailField"
              formik={formik}
              value={formik.values.email}
          />


          <TextInput
            label={"Password:"}
            customStyles="flex-col mb-14 w-full"
            placeholder={"* * * * * *"}
            labelStyles="self-start font-medium"
            customInputStyles="h-12  w-full"
            type="password"
            name={"password"}
            id="Login.PasswordField"
            onChange={(e) => {
              formik.handleChange("password")(e.target.value);
            }}
            formik={formik}
            value={formik.values.password}
          />


          <Button
            border
            onClick={() => formik.handleSubmit()}
            width="360"
            type="main"
            id="Login.LoginButton"
            isLoading={isPending}
            buttonType={"submit"}
          >
            Login
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
