import axiosInstance from '../../../utils/axios';
import { IErrorResponse, QueryKeys } from '../../querys/querys.enum';
import { useAppQuery } from '../../querys/useAppQuery';
import { AxiosError } from 'axios';
import { ISubscriptionResponse } from '../../../models/Interface/Subscription.interface';

const useSubscription = (subscriptionId: string | undefined) => {
  return useAppQuery<ISubscriptionResponse, AxiosError<IErrorResponse>>({
    queryKey: [
      QueryKeys.SUBSCRIPTION,
      {
        subscriptionId,
      },
    ],
    queryFn: async () => {
      return (await axiosInstance.get(`/subscriptions/${subscriptionId}`)).data;
    },
    enabled: !!subscriptionId,
    retry: 0,
  });
};
export default useSubscription;
