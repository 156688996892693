const generateRandomPassword = () => {
  const PASSWORD_LENGTH = 24;
  const chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*-';
  let password = '';
  for (let i = 0; i < PASSWORD_LENGTH; i++) {
    password += chars[Math.floor(Math.random() * chars.length)];
  }
  return password;
};

export default generateRandomPassword;
