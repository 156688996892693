import {FormikValues} from "formik";
import React from "react";

export const InputErrorMessage :React.FC<{props: FormikValues, type: string}>= (dto:{props: FormikValues, type: string}) => {
    const {props, type} = dto

    if (!props || !type){
        return  null
    }

    const checkYupInputs = (props: any, type: string) => {
        return !!props.errors[type] && !!props.touched[type]
    }

    return (
        checkYupInputs(props, type) ? (
            <div className="text-error text-[13px] font-firago">{props.errors[type]}</div>
        ) : null
    )
}



