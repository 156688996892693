import Button from '../../commons/Button/Button';
import Typography from '../../commons/Typography/Typography';
import '../../css/ScrollbarStyles.css';
import { useNavigate } from 'react-router';
import usePrompts from '../../state/hooks/prompts/usePrompts';
import formatPromptType from '../../utils/helpers/formatPrompt';

const Prompts = () => {
  const navigate = useNavigate();
  const { data, isLoading, isPending, isError, error, isFetching } =
    usePrompts();

  if (isLoading || isPending || isFetching) {
    return <div>Loading...</div>;
  }

  if (isError && error) {
    return (
      <div className="flex flex-1 justify-center items-center h-full">
        <Typography>{JSON.stringify(error.response?.data.message)}</Typography>
      </div>
    );
  }

  return (
    <div className="flex px-30 pt-55 pb-20 flex-col bg-light-grey-1 h-full">
      <div className="flex justify-between flex-wrap content-center mb-30">
        <h2 className="text-dark-grey font-bold text-2xl">Prompts</h2>
        <Button
          type="secondary"
          border
          onClick={() => navigate('/new-prompt')}
          id="Prompts.AddNewPromptButton"
        >
          Add New Prompt
        </Button>
      </div>
      <div className="overflow-x-auto rounded-xl border-light-grey-3 border-solid border">
        <table className="table-auto w-full overflow-x-auto bg-white rounded-xl border-collapse text-dark-grey">
          <thead className="sticky top-0">
            <tr className="bg-gray-100 h-48 bg-light-grey-4">
              <th className="pl-20 text-left">
                <Typography weight="extrabold">Title</Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.prompts.map((prompt) => (
              <tr
                className="cursor-pointer border-light-grey-3 border-solid border-t hover:bg-light-grey-1"
                key={prompt.promptType}
                onClick={() => navigate(`/prompts/${prompt.promptType}`)}
              >
                <td className="px-20 py-20">
                  <Typography weight="semibold">
                    {formatPromptType(prompt.promptType)}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Prompts;
