import { QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

interface AppQueryOptions<TData, TError> extends UseQueryOptions {
    queryKey: QueryKey;
    queryFn: QueryFunction<TData>,
    options?: UseQueryOptions<TData, TError>
}

export function useAppQuery<TData = unknown, TError = unknown>({
    queryKey,
    queryFn,
    options,
}: AppQueryOptions<TData, TError>
): UseQueryResult<TData, TError> {
    return useQuery({
        queryKey,
        queryFn: queryFn,
        ...options,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}