import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import axiosInstance from '../../../utils/axios';
import { defaultQueryOptions, QueryKeys } from '../../querys/querys.enum';
import { IUsersRepsonse } from '../../../models/Interface/User.interface';

export interface IFileterInterface {
  value: string;
  label: string;
  search?: string;
}
export interface IFilters {
  email?: string;
  firstName?: string;
  lastName?: string;
  search?: string;
  id?: number;
  verified?: boolean;
  hadPremium?: boolean;
  subUserId?: string;
  accountType?: string;
}

const useSubUsers = (isEnabled: boolean, params: IFilters) => {
  return useInfiniteQuery<IUsersRepsonse>({
    queryKey: [QueryKeys.SUB_USERS, params],
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1, queryKey }) => {
      const d = queryKey[1] as IFilters;

      const email = d.email ? `&email=${encodeURIComponent(d.email)}` : '';
      const firstName = d.firstName
        ? `&firstName=${encodeURIComponent(d.firstName)}`
        : '';
      const lastName = d.lastName
        ? `&lastName=${encodeURIComponent(d.lastName)}`
        : '';
      const search = d.search ? `&search=${encodeURIComponent(d.search)}` : '';
      const id = d.id ? `&id=${encodeURIComponent(d.id)}` : '';
      const subUserId = d.subUserId
        ? `&subUserId=${encodeURIComponent(d.subUserId)}`
        : '';
      const verified = d.verified
        ? `&verified=${encodeURIComponent(d.verified)}`
        : '';
      const hadPremium = d.hadPremium
        ? `&hadPremium=${encodeURIComponent(d.hadPremium)}`
        : '';

      const accountType = d.accountType
        ? `&accountType=${encodeURIComponent(d.accountType.toUpperCase())}`
        : '';

      return (
        await axiosInstance.get(
          `/users?page=${pageParam}${email}${firstName}${lastName}${accountType}${search}${id}${subUserId}${verified}${hadPremium}`
        )
      ).data;
    },
    getNextPageParam: (lastPage, allPages, lastPageParam, allParams) => {
      if (lastPage.meta.totalPage === lastPage.meta.page) return undefined;
      return lastPage.meta.page + 1;
    },
    ...defaultQueryOptions,
    refetchOnWindowFocus: true,
    initialData: undefined,
    enabled: isEnabled,
    placeholderData: keepPreviousData,
  });
};
export default useSubUsers;
