export const mapLabelToKey = (
  label: string,
  map: { [x: string]: string } | undefined
) => {
  for (const key in map) {
    if (map[key] === label) {
      return key;
    }
  }
  return label;
};
